import {useEffect, useState} from "react";
import * as React from "react";
import Form from 'react-bootstrap/Form';
import DatePicker from "react-datepicker";
import TimePicker from 'react-bootstrap-time-picker';
import "react-datepicker/dist/react-datepicker.css";

import './form.css'
export default function TimeRange(props) {
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    useEffect(()=>{
        console.log('props.value', props.value)
        //setValueDate(props.value)
        if(props.value) {
            setStart(parseInt(props.value.plan_start) * 60*60)
            setEnd(parseInt(props.value.plan_end) * 60*60)
        }
    },[props.value])
    useEffect(()=>{
        console.log('start',start)
        console.log('end',end)
        if(start && end) {
            props.onChangeStart(start/60/60)
            props.onChangeEnd(end/60/60)
        }
    },[start, end])

      return (
          <div  style={{marginBottom:10}}>
              <Form.Label>{props.label}</Form.Label>
              <div style={{display:'flex'}}>
                  <TimePicker value={start} format={24} start="00:00" end="23:59" step={60} onChange={(time) => setStart(time)} />
                  <div style={{marginLeft:10, marginRight:10, marginTop:5}}>-</div>
                  <TimePicker value={end} format={24} start="00:00" end="23:59" step={60} onChange={(time) => setEnd(time)} />
              </div>
          </div>
      );
}
