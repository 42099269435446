import React, {useCallback, useEffect, useState} from "react";
import Container from 'react-bootstrap/Container';
import '../../style.css'
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";

import DroneVideo from "../../components/droneVideo";
import VideoByImage from "../../components/videoByImage";
import DetectorsService from "../../api/detectorsService";
import DetectorMap from "./map/detectorMap";
import useWebSocket, { ReadyState } from 'react-use-websocket';
import {Button} from "react-bootstrap";

const Detector = () => {
    const { detectorId } = useParams()
    const [detector, setDetector] = useState()
        //new WebSocket('ws://89.111.140.84:3000')
    //const { sendMessage, lastMessage, readyState } = useWebSocket('ws://89.111.140.84:3000');
    let navigate = useNavigate();
    // useEffect(() => {
    //     console.log(lastMessage)
    // }, [lastMessage]);

    //const handleClickSendMessage = useCallback(() => sendMessage('Hello'), []);
    useEffect(()=>{
        if (detectorId) getDetector(detectorId)
    },[detectorId])

    const getDetector = async (id) =>{
        const res = await DetectorsService.getDetector(navigate, id)
        if (res.status == "OK"  &&  res.item) {
            setDetector(res.item)
        }
    }

    return <Container>
        {!detectorId && <h1 className="h">Добавить детектор</h1>}
        {/*{mission && mission.drone_number && mission.status !== "new" && <DroneVideo droneNumber={mission.drone_number} />}*/}
        {/*{false && detector && <VideoByImage number={mission.drone_number} />}*/}
        {/*<Button onClick={handleClickSendMessage}>SendMessage</Button>*/}

        <DetectorMap detector={detector} setDetector={setDetector} />
    </Container>
}

export default Detector
