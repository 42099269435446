import React from "react";
import FormBuilder from '../components/form'
import AuthService from "../api/authService";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import {loginAction} from "../redux/authSlice";
import {showError} from "../redux/errorSlice";
import {useDispatch} from "react-redux";
import Container from "react-bootstrap/Container";
import '../style.css'

const fields = [
    // {
    //     propertyName:'photo',
    //     name:'Photo',
    //     type:'image',
    //     value:''
    // },
    {
        propertyName:'email',
        name:'Логин',
        type:'text',
        value:''
    },
    // {
    //     propertyName:'phone',
    //     name:'Телефон',
    //     type:'text',
    //     value:''
    // },
    // {
    //     propertyName:'name',
    //     name:'Позывной',
    //     type:'text',
    //     value:''
    // },
    // {
    //     propertyName:'birthday',
    //     name:'Birthday',
    //     type:'date',
    //     value:''
    // },
    // {
    //     propertyName:'aboutme',
    //     name:'About me',
    //     type:'textarea',
    //     value:''
    // },
    {
        propertyName:'code',
        name:'Код',
        type:'password',
        value:''
    },
    {
        propertyName:'password',
        name:'Пароль',
        type:'password',
        value:''
    },
    {
        propertyName:'repeatPassword',
        name:'Повторите пароль',
        type:'password',
        value:''
    },
    // {
    //     propertyName:'country',
    //     name:'Country',
    //     buttonText: 'Country',
    //     type:'select',
    //     required:true,
    //     items:[
    //         {name: 'Russia', value:'RU'},
    //         {name: 'China', value:'CN'},
    //         {name: 'India', value:'IN'},
    //     ]
    // },

]
function Registration() {
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const submit = async (form)=>{
        console.log('form',form)
        let body = {...form}
        const result = await AuthService.registration( navigate, body)
        console.log(result)
        if(result.status == 'OK'){
            dispatch(loginAction(result.userProfile))
            navigate('/drones')
        }
        else if(result.status == 'error') {
            if(result.errors){
                result.errors.forEach((error)=>dispatch(showError(error.msg)))
            }else
                dispatch(showError(result.message))
        }
    }
    return <div className="auth-container"><div style={{maxWidth:'350px', width:'100%'}} className="d-grid gap-2">
        <FormBuilder fields={fields} buttonName={'Зарегистрироваться'}  submit={submit}/>
        <Button  variant="secondary" mode="contained" onClick={() => {navigate('/login')}} style={{marginBottom:10,marginLeft:'20px', marginRight:'20px'}}>
            {'Войти'}
        </Button>
        <div className="spacer" />
    </div></div>
}

export default Registration;
