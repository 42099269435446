import React, {useState, useEffect, useRef} from "react";

import './itemCard.css';
import Modal from 'react-bootstrap/Modal';
import FormBuilder from "./form";
import {useNavigate} from "react-router-dom";
import IconPencilSquare from "../icons/editIcon";
import {loginAction} from "../redux/authSlice";
import {showError} from "../redux/errorSlice";
import AuthService from "../api/authService";
import {useDispatch} from "react-redux";
import UsersService from "../api/usersService";
import DronesService from "../api/dronesService";
import {Button} from "react-bootstrap";
import IconTrash from "../icons/others";
import IconEye from "../icons/eye";
import config from "../config.json";
import IconBxTargetLock from "../icons/target";


const VideoByImage = (props) => {


    const [image,setImage] = useState(null)
    const [alt,setAlt] = useState(null)

    const img = useRef()
    const timeoutId = useRef()

    const onImageClick = async (e)=>{
        const rect = e.target.getBoundingClientRect()
        const realWidth = e.target.naturalWidth;
        const realHeight = e.target.naturalHeight;
        const resize = realWidth/rect.width
        const coordX = parseInt(((e.pageX -  rect.left)*resize).toFixed(0))
        const coordY = parseInt(((e.pageY - rect.top - window.scrollY)*resize).toFixed(0))
        console.log(coordX,coordY)
        await sendClick({coordX,coordY,image:e.target.alt})
    }
    const sendClick = async (values) =>{
        let data = {method: "post"}
        data['body'] = JSON.stringify({...values, dronNumber:props.number })
        data['headers'] = {
            'Content-Type': 'application/json'
        }
        let res = await fetch(config.apiServerPath + '/click/set/', data)

    }


    const getImage = async (imgFileName) =>{
        try {
            let random = (Math.random() + 1).toString(36).substring(7);
            let urlWithRandom = config.filesPath + props.number + "/" + imgFileName + '?' + random
            const r = await fetch(urlWithRandom)//{ headers: { Authorization: `bearer ${accessToken}` } }
            if(r && r.status == 200)
            {const d = await r.blob()
                const imagePath = window.URL.createObjectURL(d)
                if(imagePath) {
                    setImage(imagePath)
                    setAlt(imgFileName)
                    img.current = imgFileName
                }
            }
        } catch (e) {
            console.log(e);
        }
    }
    const changeImage = async ()=>{
        try {
                let res = await fetch(config.apiServerPath + '/image/get/' + props.number, {
                    method: 'get',
                    credentials: 'same-origin',
                    mode: "cors",
                    headers: {
                        Origin: window.location.origin,
                        "Access-Control-Request-Method": "GET",
                        "Access-Control-Request-Headers": "Content-Type",
                        "sec-fetch-dest": "image",
                    },
                })
                if (res.status === 200) {
                    res = await res.json()
                    if(res.file) {
                        const file = res.file
                        if (img.current !== file.url) {
                            await getImage(file.url)
                        }
                    }else{
                        setImage('')
                        img.current = ''
                    }
                } else {
                    setImage('')
                    img.current = ''
                }
            timeoutId.current = setTimeout(()=>{changeImage()}, 100)
        }catch(e){
            console.log(e)
        }

    }
    useEffect(()=>{
        console.log(" START " , img.current)
        if(img.current === undefined && props.number) changeImage()
        return () => clearTimeout(timeoutId.current)
    },[props.number])
    useEffect(()=>{
        return () => {
            console.log("CLEAR TIMEOUT")
            clearTimeout(timeoutId.current)
        }
    },[])

    return <div className="App" style={{marginBottom:20}}>
                <header className="App-header">
                    <div>
                        {image && <img style={{maxWidth:'100%'}} alt={alt} src={ image } onClick={onImageClick}/>}
                    </div>
                    {!image && <p>
                        Видео не найдено
                    </p>}

                </header>
            </div>
}
export default VideoByImage
