import React, {useEffect, useRef, useState} from "react";
import { Button, YMaps, Map, Placemark, Polyline, FullscreenControl, TypeSelector, RulerControl } from '@pbe/react-yandex-maps';

import '../itemCard.css';
import {useNavigate} from "react-router-dom";
import MissionsService from "../../api/missionsService";
import {getDistanceFromLatLonInKm, getPointsFromActions} from "../../utils/mapHelpers";
import config from "../../config.json";
const CurrentPosition = ({ drone_number, targetPoint }) => {
    const [position, setPosition] = useState(null);
    const timeOutId = useRef([]);

    let navigate = useNavigate();

    const dronsRef = useRef('');
    useEffect(()=>{
        return ()=>{
            clearTimeout(timeOutId.current)
        }
    },[])
    useEffect(()=>{
        if(!dronsRef.current) {
            dronsRef.current = drone_number
            getCurrentPosition()
        }else{
            dronsRef.current = drone_number
        }
    },[drone_number])
    useEffect(() => {
        console.log('position', position);
    }, [position])
    const getPosition = async ( drone_number) => {
        const res = await MissionsService.getPosition(navigate, drone_number)

        if (res && res.lat !== undefined) {
            setPosition(res)
        }
    }
    const getCurrentPosition = async ()=> {
        console.log(timeOutId.current)
        if(dronsRef.current) timeOutId.current = setTimeout(async () => {
            await getPosition(dronsRef.current)
            getCurrentPosition(dronsRef.current)
        }, 1000)
    }
    return <>
        {position && <Placemark
       // properties={{ iconContent: i !== 0 ? `<div class='iconContent'>${getDistance(i)}км</div>` : '' }}
        modules={["templateLayoutFactory", "layout.ImageWithContent", 'geoObject.addon.balloon', 'geoObject.addon.hint']}
        options={{
            iconLayout: 'default#imageWithContent',
            // Своё изображение иконки метки.
            iconImageHref: config.serverPath + "/img/bullseye.svg",
            iconImageSize: [30, 30],
            iconImageOffset: [-15, -15],
        }}
        geometry={[position.lat, position.lon]}
    />}
        {position && targetPoint && <Polyline
            geometry={[[position.lat, position.lon], [targetPoint.lat, targetPoint.lon]]}

            options={{
                balloonCloseButton: false,
                strokeColor: "#000000",
                strokeWidth: 4,
                strokeOpacity: 0.5,
            }}
        />}
    </>
}
export default CurrentPosition
