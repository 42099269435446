import React, {useEffect, useRef, useState} from "react";
// import MapSetPoint from './mapSetPoint'
// import ImageInput from './imageInput'
// import BooleanInput from './booleanInput'
//import MultiSelector from './multiSelector'
import TextCustomInput from './textInput'
import Button from 'react-bootstrap/Button';
import DateTime from "./dateTime";
import BooleanInput from "./booleanInput";
import Select from "./select";
import ImageInput from "./imageInput";
import TimeRange from "./timeRange";
import BooleanGroup from "./booleanGroup";
import TextAreaCustomInput from "./textAreaInput";
import DateInput from "./date";



const FormBuilder = (props)=>{

    const [output, setOutput] = useState({})
    let stateOutput = useRef({})
    useEffect(()=>{
        if(props.values) {
            stateOutput.current = {...props.values}
            setOutput(stateOutput)
        }
    },[])

    const setOutputField = (fieldName, value, lang)=>{
        if(lang) {
            if(!stateOutput.current[fieldName]) stateOutput.current[fieldName] = {en:'',ru:''}
            stateOutput.current[fieldName][lang] = value
        }
        else
            stateOutput.current[fieldName] = value
        setOutput(stateOutput.current)
    }
    const submit = () =>{
        props.submit(output);
    }

    const onSetPoint = (item, point)=>{
        setOutputField(item.propertyName, point)
    }
    return <div >
        <div style={{ padding: 20}} className="d-grid gap-2">
            {props.fields.map((item,i)=>{

                if(item.visible !== false) {
                    if (item.type == 'boolean')
                        return <BooleanInput key={'boolean_' + i}
                                            value={props.values && props.values[item.propertyName] ? props.values[item.propertyName] : item.value}
                                             label={item.name}
                                             onChange={(value) => {
                                           setOutputField(item.propertyName, value)
                                       }} />
                    if (item.type == 'booleanGroup')
                        return <BooleanGroup key={'boolean_group' + i}
                                             value={props.values && props.values[item.propertyName] ? props.values[item.propertyName] : item.value}
                                             label={item.name}
                                             items={item.items}
                                             onChange={(value) => {
                                                 setOutputField(item.propertyName, value)
                                             }} />
                    if (item.type == 'text')
                        return <TextCustomInput multiline = {false}
                                                value={props.values && props.values[item.propertyName] ? props.values[item.propertyName] : item.value}
                                                label={item.name}
                                                type={'text'}
                                                key={'text_' + i}
                                                onChange={(value)=>{setOutputField(item.propertyName, value)}}/>
                    if (item.type == 'password')
                        return <TextCustomInput multiline = {false}
                                                value={props.values && props.values[item.propertyName] ? props.values[item.propertyName] : item.value}
                                                label={item.name}
                                                type={'password'}
                                                key={'text_password_' + i}
                                                onChange={(value)=>{setOutputField(item.propertyName, value)}}/>
                    if (item.type == 'timeRange')
                        return <TimeRange
                                         valueStart={props.values && props.values[item.propertyNameStart] ? props.values[item.propertyNameStart] : item.value}
                                         valueEnd={props.values && props.values[item.propertyNameEnd] ? props.values[item.propertyNameEnd] : item.value}
                                         label={item.name}
                                         key={'time_range_' + i}
                                         onChangeStart={(value)=>{setOutputField(item.propertyNameStart, value)}}
                                         onChangeEnd={(value)=>{setOutputField(item.propertyNameEnd, value)}}/>
                    if (item.type == 'dateTime')
                        return <DateTime multiline = {false}
                                         value={props.values && props.values[item.propertyName] ? props.values[item.propertyName] : item.value}
                                         label={item.name}
                                         key={'date_time_' + i}
                                         onChange={(value)=>{setOutputField(item.propertyName, value)}}/>
                    if (item.type == 'date')
                        return <DateInput multiline = {false}
                                         value={props.values && props.values[item.propertyName] ? props.values[item.propertyName] : item.value}
                                         label={item.name}
                                         key={'date_' + i}
                                         onChange={(value)=>{setOutputField(item.propertyName, value)}}/>
                    if (item.type == 'select')
                        return <Select key={'multiSelector_' + i}
                                       label={item.name}
                                              items={item.items}
                                       required={item.required}
                                              value={props.values && props.values[item.propertyName] ? props.values[item.propertyName] : null}
                                              onChange={(selectedItems)=>{setOutputField(item.propertyName, selectedItems)}} />
                    // if (item.type == 'textarea')
                    //     return <TextCustomInput multiline = {true}
                    //                             value={props.values && props.values[item.propertyName] ? props.values[item.propertyName] : item.value}
                    //                             label={item.name}
                    //                             key={'textarea_custom_' + i}
                    //                             onChange={(lang,value)=>{setOutputField(item.propertyName, value,lang)}}/>
                    // if (item.type == 'textWithoutLang')
                    //     return <TextCustomInput key={'text_custom_whithoutlang' + i}key={i}
                    //                       label={item.name}
                    //                       defaultValue={props.values && props.values[item.propertyName] ? props.values[item.propertyName] : item.value}
                    //                       onChange={(event) => {
                    //                           setOutputField(item.propertyName, event.nativeEvent.text)
                    //                       }}
                    //
                    //                       style={{marginBottom:15, marginTop:10}}
                    //                       mode={'outlined'}
                    //     />
                    if (item.type == 'textarea')
                        return <TextAreaCustomInput
                                                value={props.values && props.values[item.propertyName] ? props.values[item.propertyName] : item.value}
                                                label={item.name}
                                                type={'textarea'}
                                                key={'text_' + i}
                                                onChange={(lang,value)=>{setOutputField(item.propertyName, value)}}/>
                    // if (item.type == 'location')
                    //     return <MapSetPoint key={'map_' + i}
                    //                         onSetPoint={(point) => {
                    //                             onSetPoint(item, point)
                    //                         }}
                    //                         value={props.values && props.values[item.propertyName] ? props.values[item.propertyName] : null}
                    //     />
                    //
                    if (item.type == 'image')
                        return <ImageInput key={'image_' + i}
                                           label={item.name}
                                           value={props.values && props.values[item.propertyName.replace("_uri", "")] ? props.values[item.propertyName.replace("_uri", "")] : null}
                                           setFileUri={(uri)=>{setOutputField(item.propertyName + '_uri', uri)}} />
                    // if (item.type == 'multiSelector')
                    //     return <MultiSelector key={'multiSelector_' + i}
                    //                           items={item.items}
                    //                           value={props.values && props.values[item.propertyName] ? props.values[item.propertyName] : null}
                    //                           onChange={(selectedItems)=>{setOutputField(item.propertyName, selectedItems)}} />
                }else{
                    if(!output[item.propertyName]) setOutputField(item.propertyName, item.value)
                }
            })}
        <Button  variant="primary" mode="contained" onClick={() => {submit()}} style={{ marginTop:20}}>
            {props.buttonName}
        </Button>
        </div>
    </div>
}

export default FormBuilder
