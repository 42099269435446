import {createSlice} from "@reduxjs/toolkit"
import Cookies from "js-cookie";


const authSlice = createSlice({
    name:'auth',
    initialState:{
        isAuth:false,
        lang:'en',
        userProfile: null,
        shippingAddress: null,
        isStorageInit:false
    },
    reducers:{
        loginAction(state, action){
            state.isAuth = true
            state.userProfile = action.payload
        },
        setShippingAddress(state, action){
            state.shippingAddress = action.payload
        },
        setStorageInit(state){
            state.isStorageInit = true
        },
        dropShippingAddress(state){
            state.shippingAddress = null
        },
        changeLang(state, action){
            state.lang = action.payload
        },
        logoutAction(state){
            state.isAuth = false
            state.userProfile = null
        }
    }
})

export default authSlice.reducer
export const {loginAction, setShippingAddress, setStorageInit,changeLang, logoutAction,dropShippingAddress} = authSlice.actions
