import React, {useEffect, useMemo, useRef, useState} from "react";
import { Button, YMaps, Map, Placemark, Polyline, FullscreenControl, TypeSelector, RulerControl, Circle } from '@pbe/react-yandex-maps';

import './itemCard.css';
import config from "../../../config.json";
import ControlsEmulator from "./controls";
import PointEditModalForm from "./pointEditModalForm";
import EmulatorsService from "../../../api/emulatorsService";
import {useNavigate} from "react-router-dom";
function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

const EmulatorMap = ({ emulator, setEmulator}) => {
    const [position, setPosition] = useState(null)
    const [ targetPoint, setTarget] = useState(null)
    const [points, setPoints] = useState([])
    const [show, setShow] = useState(false);

    const [addPoint, setAddPoint] = useState(false)
    const [addPath, setAddPath] = useState(false)

    const [indexSelectedPoint, setIndexSelectedPoint] = useState()
    const [editMode, onChangeEditMode] = useState(null)
    const timeOutId = useRef();
    const pointRef = useRef();

    let navigate = useNavigate();


    const onChangePoint = (values) => {
        const newPoints = [...points]
        newPoints[indexSelectedPoint] = { ...newPoints[indexSelectedPoint], ...values, lat: parseFloat(values.lat), lon: parseFloat(values.lon)}
        setPoints(newPoints)
        EmulatorsService.sendTarget(navigate, {
            message: JSON.stringify(
                {
                    payload: {
                        point: {
                            longitude: newPoints[indexSelectedPoint].lat,
                            latitude: newPoints[indexSelectedPoint].lon,
                        },
                        privateID: newPoints[indexSelectedPoint].privateID,
                        info: {
                            "distance": "0",
                            "following": "false",
                            "active": "false",
                            "azimuth": "167.51902642483466",
                            "speed": "10.0",
                            "height": newPoints[indexSelectedPoint].alt
                        }
                    },
                    topic: 'drones'
                }),
            detectorNumber: emulator.detector_number
        })
        setShow(false);
    }


    const getDistance = (i) => {
        //return getDistanceFromLatLonInKm(points[i], points[i-1])
        return 0;
    }
    const onPointClick = (i) => {
        setAddPoint(false);
        setIndexSelectedPoint(i)
        setShow(true);
    }
    const onCloseChangePoint = () => {
        setShow(false)
    }
    const onMapClick = (e) => {
        //TODO edit mode сделать по кнопке на карте как в миссии
        const coords = e.get('coords')
        if(addPath){
            setTarget({ lat: coords[0], lon: coords[1] })
            setAddPath(false);
        }
        else if (addPoint) {
            const newPoints = [...points]
            const newPoint = {lat: coords[0], lon: coords[1], alt: 50, privateID: getRandomInt(5000) }
            EmulatorsService.sendTarget(navigate, {
                message: JSON.stringify(
                {
                        payload: {
                            point: {
                                longitude: coords[1],
                                latitude: coords[0]
                            },
                            privateID: newPoint.privateID,
                            info: {
                                "distance": "0",
                                "following": "false",
                                "active": "false",
                                "azimuth": "167.51902642483466",
                                "speed": "10.0",
                                "height": newPoint.alt
                            }
                        },
                        topic: 'drones'
                    }),
                    detectorNumber: emulator.detector_number
            })
            setAddPoint(false);

            newPoints.push(newPoint)
            setIndexSelectedPoint(newPoints.length -1)
            setPoints(newPoints);

        }else if(indexSelectedPoint !== null){
            console.log(e.get('coords'))
            const newPoints = [...points]
            const newPoint = { ...newPoints[indexSelectedPoint], lat:coords[0], lon: coords[1] }
            EmulatorsService.sendTarget(navigate, {
                message: JSON.stringify(
                    {
                        payload: {
                            point: {
                                longitude: coords[1],
                                latitude: coords[0]
                            },
                            privateID: newPoint.privateID,
                            info: {
                                "distance": "0",
                                "following": "false",
                                "active": "false",
                                "azimuth": "167.51902642483466",
                                "speed": "10.0",
                                "height": newPoint.alt
                            }
                        },
                        topic: 'drones'
                    }),
                detectorNumber: emulator.detector_number
            })
            newPoints[indexSelectedPoint] = newPoint
            setPoints(newPoints)
        }
    }
    const movePoint = async (latDiff, lonDiff) => {
        const newPoints = [...points]
        const newPoint = {
            ...newPoints[indexSelectedPoint],
            lat: pointRef.current.lat + latDiff,
            lon: pointRef.current.lon + lonDiff
        }
        pointRef.current = newPoint
        console.log("move", newPoint);
        await EmulatorsService.sendTarget(navigate, {
            message: JSON.stringify(
                {
                    payload: {
                        point: {
                            longitude: newPoint.lon,
                            latitude: newPoint.lat
                        },
                        privateID: newPoint.privateID,
                        info: {
                            "distance": "0",
                            "following": "false",
                            "active": "false",
                            "azimuth": "167.51902642483466",
                            "speed": "10.0",
                            "height": newPoint.alt
                        }
                    },
                    topic: 'drones'
                }),
            detectorNumber: emulator.detector_number
        })
        newPoints[indexSelectedPoint] = newPoint
        setPoints(newPoints)
    }
    const moveSelectedPoint = (latDiff, lonDiff)=> {
        timeOutId.current = setTimeout(async () => {
            await movePoint(latDiff, lonDiff)
            moveSelectedPoint(latDiff, lonDiff)
        }, 1000)
    }
    const start = () => {
        if(timeOutId.current){
            clearTimeout(timeOutId.current)
            timeOutId.current = undefined;
        }else{
            pointRef.current = points[indexSelectedPoint];
            const latDiff = (targetPoint.lat - points[indexSelectedPoint].lat) / 100;
            const lonDiff = (targetPoint.lon - points[indexSelectedPoint].lon) / 100;
            moveSelectedPoint(latDiff, lonDiff);
        }
    }
    return <div>
        <ControlsEmulator
            detector={emulator}
            onChangeEditMode={onChangeEditMode}
            setPosition={setPosition}
            position={position}
            editMode={editMode}
            setDetector={setEmulator}>
            <YMaps>
                <Map
                    onClick={onMapClick}
                    options={{dragCursor: "arrow", fullscreenZIndex: 50,}}
                    width={'100%'}
                    height={500}
                    defaultState={{ center: position && position.lat ? [position.lat, position.lon] : [49.138505, 32.697259] , zoom: 12 }}
                >
                    {points && points.map((point, i)=><Placemark
                        key={ "point" + i }
                        properties={{ iconContent: '' }}
                        modules={["templateLayoutFactory", "layout.ImageWithContent", 'geoObject.addon.balloon', 'geoObject.addon.hint']}
                        onClick={() => onPointClick(i)}
                        options={{
                            iconLayout: 'default#imageWithContent',
                            // Своё изображение иконки метки.
                            iconImageHref:  i === indexSelectedPoint ? config.serverPath + "/img/crosshair2.svg" :  config.serverPath + "/img/crosshair.svg",
                            iconImageSize: [30, 30],
                            iconImageOffset: [-15, -15],
                        }}
                        geometry={[point.lat, point.lon]}
                    /> )}
                    {targetPoint && <Placemark
                        properties={{ iconContent: '' }}
                        modules={["templateLayoutFactory", "layout.ImageWithContent", 'geoObject.addon.balloon', 'geoObject.addon.hint']}
                        options={{
                            iconLayout: 'default#imageWithContent',
                            // Своё изображение иконки метки.
                            iconImageHref: config.serverPath + "/img/geo-alt-fill.svg",
                            iconImageSize: [30, 30],
                            iconImageOffset: [-15, -30],
                        }}
                        geometry={[targetPoint.lat, targetPoint.lon]}
                    />}
                    {indexSelectedPoint !== undefined && targetPoint && <Polyline
                        geometry={[[targetPoint.lat, targetPoint.lon], [points[indexSelectedPoint].lat, points[indexSelectedPoint].lon]]}
                        options={{
                            balloonCloseButton: false,
                            strokeColor: "#000000",
                            strokeWidth: 4,
                            strokeOpacity: 0.5,
                        }}
                    />}
                    <Button
                        options={{maxWidth: 128, float: "left"}}
                        state={{ enabled: indexSelectedPoint !== undefined && targetPoint }}
                        data={{content: "Запуск"}}
                        onClick={() => start()}
                    />
                    <Button
                        options={{maxWidth: 128, float: "left"}}
                        data={{content: "Добавить путь"}}
                        state={{selected: addPath, enabled: indexSelectedPoint !== undefined}}
                        onClick={() => setAddPath(!addPath)}
                    />
                    <Button
                        options={{ maxWidth: 128, float: "left" }}
                        data={{ content: "Добавить цель" }}
                        state={{ selected: addPoint }}
                        onClick={() => setAddPoint(!addPoint)}
                    />

                    <FullscreenControl />
                    <TypeSelector options={{ float: "right" }} />
                    <RulerControl options={{ float: "right" }} />
                </Map>
            </YMaps>
        </ControlsEmulator>
        <PointEditModalForm
            point={ indexSelectedPoint !== null ? points[indexSelectedPoint] : null }
            onSubmit={onChangePoint}
            onClose={onCloseChangePoint}
            editMode={editMode}
            setShow={setShow}
            show={show}
        />
    </div>
}
export default EmulatorMap
