import fetchData from "./fetchData";
import api from "./api";

export default class EcoService {
  static async get(navigation, body) {
    const res = await fetchData(api.ecoResultGet, navigation, 'post', {...body})
    return res
  }

  static async merge(navigation, body) {
    const res = await fetchData(api.ecoResultMerge, navigation, 'post', {...body})
    return res
  }
}
