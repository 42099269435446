import React, {useState, useEffect} from "react";

import './itemCard.css';
import Modal from 'react-bootstrap/Modal';
import FormBuilder from "./form";
import {useNavigate} from "react-router-dom";
import IconPencilSquare from "../icons/editIcon";
import {loginAction} from "../redux/authSlice";
import {showError} from "../redux/errorSlice";
import AuthService from "../api/authService";
import {useDispatch} from "react-redux";
import UsersService from "../api/usersService";
import DronesService from "../api/dronesService";
import {Button} from "react-bootstrap";
import IconTrash from "../icons/others";
import IconEye from "../icons/eye";
import IconBxCameraMovie from "../icons/movie";
import ReactPlayer from 'react-player'


const DroneWatchRecordModalForm = (props) => {

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    let navigate = useNavigate();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const deleteDrone = async ()=>{

        const result = await DronesService.delete( navigate, props.values._id)

        setLoading(false)
        if(result.status == 'OK'){
            handleClose();
            props.refresh();
        }
        else if(result.status == 'error') {
            if(result.errors){
                result.errors.forEach((error)=>dispatch(showError(error.msg)))
            }else
                dispatch(showError(result.message))
        }

    }

    return <div >
        <div  >
            <IconBxCameraMovie onClick={handleShow} style={{cursor:'pointer'}} fill={'white'} color={'white'} width={'20px'} />

        </div>
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>{'Просмотр записи'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {/*<ReactPlayer url='http://89.111.140.133/files/SampleVideo_1280x720_1mb.mp4' />*/}
           <video width="100%" preload="auto" autoplay="true" controls="controls" codecs="avc1.4d002a, mp4a.40.2">
               <source src={'http://89.111.140.133/files/SampleVideo_1280x720_1mb.mp4'} type='video/mp4' />
            </video>
        </Modal.Body>

    </Modal>
    </div>
}
export default DroneWatchRecordModalForm
