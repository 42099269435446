import React, {useState, useEffect} from "react";

import './itemCard.css';
import Modal from 'react-bootstrap/Modal';
import FormBuilder from "./form";
import {useNavigate} from "react-router-dom";
import IconPencilSquare from "../icons/editIcon";
import {loginAction} from "../redux/authSlice";
import {showError} from "../redux/errorSlice";
import AuthService from "../api/authService";
import {useDispatch} from "react-redux";
import UsersService from "../api/usersService";
import DronesService from "../api/dronesService";
import {Button} from "react-bootstrap";
import IconTrash from "../icons/others";

const fields = [

    // {
    //     propertyName:'phone',
    //     name:'Телефон',
    //     type:'text',
    //     value:''
    // },
    {
        propertyName:'name',
        name:'Название',
        type:'text',
        value:''
    },
    {
        propertyName:'number',
        name:'Номер',
        type:'text',
        value:''
    },
    {
        propertyName:'vpnAddress',
        name:'Адрес VPN',
        type:'text',
        value:''
    },

]
const DroneDeleteModalForm = (props) => {

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    let navigate = useNavigate();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const deleteDrone = async ()=>{

        const result = await DronesService.delete( navigate, props.values._id)

        setLoading(false)
        if(result.status == 'OK'){
            handleClose();
            props.refresh();
        }
        else if(result.status == 'error') {
            if(result.errors){
                result.errors.forEach((error)=>dispatch(showError(error.msg)))
            }else
                dispatch(showError(result.message))
        }

    }

    return <div >
        <div  >
            <IconTrash onClick={handleShow} style={{cursor:'pointer'}} fill={'white'} color={'white'} width={'20px'} />

        </div>
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>{'Удалить устройство'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Button style={{marginBottom:20}} variant="secondary" onClick={handleClose} >Отмена</Button>
            <Button style={{marginBottom:20,marginLeft:20}} onClick={deleteDrone} >Удалить</Button>

        </Modal.Body>

    </Modal>
    </div>
}
export default DroneDeleteModalForm
