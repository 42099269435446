import {useEffect, useState} from "react";
import * as React from "react";
import Form from 'react-bootstrap/Form';

export default function Select(props) {
    const [lang, setLang] = useState('en');
    const [valueLang, setValueLang] = useState('en');

    useEffect(()=>{
        console.log('props.value select', props.value)
        setValueLang(props.value)
    },[props.value])
    useEffect(()=>{
        console.log('valueLang',valueLang)

    },[valueLang])
    const setValueLangFunc = (value)=>{
        let valueLangCopy = {...valueLang}
        valueLangCopy[lang] = value
        setValueLang(valueLangCopy)
        props.onChange(lang, value)
    }
    const onChange = (value)=>{
        props.onChange(value.target.value)
        console.log(value.target.value)
    }
    // <TouchableOpacity onPress={()=>{lang == 'en' ? setLang('ru'): setLang('en')}}>
    //     <View style={{flexDirection:'row'}}>
    //         <Text style={lang == 'en' ? styles.lang:null}>en</Text>
    //         <Text> / </Text>
    //         <Text style={lang == 'ru' ? styles.lang:null}>ru</Text>
    //     </View>
    // </TouchableOpacity>
      return (
          <div >
              {props.label && <Form.Label>{props.label}</Form.Label>}
              <Form.Select  aria-label="Default select example" value={props.value} onChange={onChange}>
                  {!props.required && <option value={false}>Все</option>}
                  {props.items.map((item)=><option key={item.value} value={item.value}>{item.name}</option>)}
              </Form.Select>
          </div>
      );
}

