
import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {hideError} from '../redux/errorSlice'
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";
import ToastFade from "react-bootstrap/ToastFade";




export default function Errors() {
  const error = useSelector(state => state.error)
  const dispatch = useDispatch();
useEffect(()=>{
  console.log('error', error)
},[error])
  function onPress(i){
    console.log('press');
    dispatch(hideError(i))
  }

  const ErrorsList = ()=>{
    return error ? error.errors.map((errorText,i)=>
            <Toast bg={'danger'} key={i} onClose={() => onPress(i)} delay={3000} autohide>
              <Toast.Header closeButton={true}>
                <img
                    src="holder.js/20x20?text=%20"
                    className="rounded me-2"
                    alt=""
                />
                <strong className="me-auto">Error</strong>
                {/*<small>11 mins ago</small>*/}
              </Toast.Header>
              <Toast.Body className={'text-white'}>{errorText}</Toast.Body>
            </Toast>
    ) : <div></div>
  }

  return <ToastContainer
        className="p-3"
        position={'top-end'}
        style={{ zIndex: 1 }}
    >
      <ErrorsList />
    </ToastContainer>
}
