import React, {useEffect, useMemo, useRef, useState} from "react";
import { Button, YMaps, Map, Placemark, Polyline, FullscreenControl, TypeSelector, RulerControl } from '@pbe/react-yandex-maps';

import './itemCard.css';
import Controls from "./Map/controls";
import {useNavigate} from "react-router-dom";
import MissionsService from "../api/missionsService";
import {getDistanceFromLatLon, getDistanceFromLatLonInKm, getPointsFromActions} from "../utils/mapHelpers";
import PointEditModalForm from "./Map/pointEditModalForm";
import config from "../config.json";
import CurrentPosition from "./Map/currentPosition";
const MissionMap = ({ mission, setMission}) => {
    const [points, setPoints] = useState([])
    const [addPoint, setAddPoint] = useState(false)
    const [indexSelectedPoint, setIndexSelectedPoint] = useState(null)
    const [editMode, onChangeEditMode] = useState(null)
    const [show, setShow] = useState(false);


    useEffect(()=>{
        if (!editMode) {
            setAddPoint(false)
            setIndexSelectedPoint(false)
        }
    },[editMode])

    useEffect(()=>{
        if (mission) {
            setPoints(getPointsFromActions(mission.actions))
        }
    },[mission])
    const onPointClick = (i) => {
        setAddPoint(false);
        if(editMode) setIndexSelectedPoint(i)
        setShow(true);
    }
    const onChangePoint = (values) => {
        if(editMode) {
            const newPoints = [...points]
            newPoints[indexSelectedPoint] = {...values, lat: parseFloat(values.lat), lon: parseFloat(values.lon)}
            setPoints(newPoints)
        }
        setShow(false);
    }
    const onCloseChangePoint = () => {
        setShow(false)
    }
    const getPointIconOffset = (point, i) => {
        if (i === 0 || i === points.length - 1) return [-15, -25]
        if(point.isTarget) return [-15, -15]
        return [-15, -30]
    }
    const getDistance = (i) => {
        return getDistanceFromLatLonInKm(points[i], points[i-1])
    }
    const getPointIconURL = (point, i) => {
        if (i === 0)
            return config.serverPath + "/img/geo-fill.svg"
        if (i === points.length - 1)
            return config.serverPath + "/img/geo.svg"
        if (point.isTarget)
            return config.serverPath + "/img/crosshair.svg"
        return config.serverPath + "/img/geo-alt-fill.svg"
    }
    const onMapClick = (e) => {
        console.log(indexSelectedPoint)
        const coords = e.get('coords')
        if (addPoint) {
            const newPoints = [...points]
            newPoints.push({lat: coords[0], lon: coords[1], alt: 50, wait: 0})
            setIndexSelectedPoint(newPoints.length -1)
            setPoints(newPoints)
        }else if(indexSelectedPoint !== null){
            console.log(e.get('coords'))
            const newPoints = [...points]
            newPoints[indexSelectedPoint] = { ...newPoints[indexSelectedPoint], lat:coords[0], lon: coords[1] }
            setPoints(newPoints)
        }
    }
    const getTargetPoint = () => points.find((point) => point.isTarget)
    const targetPoint = useMemo(getTargetPoint,[points])
    const getFullDistance = () => {
        let fullDistance = 0;
        for(let i=1; i < points.length; i++){
            fullDistance += getDistanceFromLatLon(points[i], points[i-1])
        }
        return fullDistance.toFixed(2);
    }

    const distance = useMemo(() => getFullDistance(), [points])

    return <div>
        <Controls mission={mission} onChangeEditMode={onChangeEditMode} points={points} editMode={editMode}>
            <YMaps>
                <Map
                    onClick={onMapClick}
                    options={{dragCursor: "arrow", fullscreenZIndex: 50,}}
                    className={"map"}
                    width={'100%'}
                    height={500}
                    defaultState={{ center: [49.138505, 32.697259], zoom: 5 }}
                >
                     {points && points.map((point, i)=><Placemark
                         key={ "point" + i }
                         properties={{ iconContent: i !== 0 ? `<div class='iconContent'>${getDistance(i)}км</div>` : '' }}
                         modules={["templateLayoutFactory", "layout.ImageWithContent", 'geoObject.addon.balloon', 'geoObject.addon.hint']}
                         onClick={() => onPointClick(i)}
                         options={{
                             iconLayout: 'default#imageWithContent',
                             // Своё изображение иконки метки.
                             iconImageHref: getPointIconURL(point, i ),
                             iconImageSize: [30, 30],
                             iconImageOffset: getPointIconOffset(point, i ),
                         }}
                         geometry={[point.lat, point.lon]}
                     /> )}
                     {points && <Polyline
                        geometry={points.map((point)=>[ point.lat, point.lon ] )}

                        options={{
                            balloonCloseButton: false,
                            strokeColor: "#000000",
                            strokeWidth: 4,
                            strokeOpacity: 0.5,
                        }}
                    />}
                    <CurrentPosition drone_number={mission? mission.drone_number : null} targetPoint={targetPoint} />
                {editMode && <Button
                        options={{ maxWidth: 128, float: "left" }}
                        data={{ content: "Добавить точку" }}
                        state={{ selected: addPoint }}
                        onClick={() => setAddPoint(!addPoint)}
                    />}
                    <FullscreenControl />
                    <TypeSelector options={{ float: "right" }} />
                    <RulerControl options={{ float: "right" }} />
                    <div className={"distance"} style={{left: editMode ? 150 : 10}}>{distance} km</div>
                </Map>
            </YMaps>
        </Controls>
        <PointEditModalForm
            point={ indexSelectedPoint !== null ? points[indexSelectedPoint] : null }
            onSubmit={onChangePoint}
            onClose={onCloseChangePoint}
            editMode={editMode}
            setShow={setShow}
            show={show}
        />
    </div>
}
export default MissionMap
