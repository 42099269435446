import {combineReducers, configureStore} from "@reduxjs/toolkit"
import authSlice from "./authSlice"
import cartSlice from "./cartSlice"
import errorSlice from "./errorSlice"

const rootReducer = combineReducers(
    {
        auth:authSlice,
        cart:cartSlice,
        error:errorSlice
    })

const store = configureStore(
    {
        reducer:rootReducer
    }
)
export default store