import React, {useState, useEffect} from "react";

//import {useDispatch, useSelector} from 'react-redux'
import './itemCard.css';
import IconFilter from "../icons/filter";
import Modal from 'react-bootstrap/Modal';
import FormBuilder from "./form";
import IconFilterRemoveOutline from "../icons/filterClear";
import IconRefresh from "../icons/refresh";
import IconUserAddLine from "../icons/add";
import RestaurantService from "../api/restaurantService";
//import {showError} from "../redux/errorSlice";
import {useNavigate} from "react-router-dom";
const moment = require('moment');
//     "0" : "Обнаружен подозрительный посетитель",
//     "1" : "Выход подозрительного посетителя",
//     "3" : "Сотрудник вошёл",
//     "4" : "Сотрудник вышел",
//     "5" : "Опоздание сотрудника",
//     "6" : "Выход сотрудника раньше графика",
//     "7" : "Выход сотрудника позже графика",
//     "8" : "Сотрудник вышел не в свою смену",
//     "10" : "Отсутствие сотрудников на кассе более 5 минут",
//     "11" : "Отсутствие сотрудников на кассе более 1 минуты, при наличии очереди",
//     "12" : "Посторонний человек за кассой",
//     "13" : "Сотрудник обнаружен",
//     "14" : "Сотрудник не состоит в базе, добавьте пожалуйста сотрудника",
const fields = [


    {
        propertyName:'picture',
        name:'Фото',
        type:'image',
        value:''
    },
    {
        propertyName:'is_employee',
        name:'Сотрудник',
        type:'boolean',
        rights:'admin',
        value:true
    },
    {
        propertyName:'is_test',
        name:'Test',
        type:'boolean',
        rights:'admin',
        value:false
    },
    {
        propertyName:'store',
        name:'Магазин',
        buttonText: 'Магазин',
        type:'select',
        items:[
            {name: '1754:г.Королев. просп.Космонавтов, 4г', value:'1754'},
            {name: '4755 Пятницкое 29', value:'4577'},
            {name: '4049 Бутырская 2', value:'4049'},
        ]
    },
    {
        propertyName:'job',
        name:'Должность',
        buttonText: 'Должность',
        rights:'admin',
        type:'select',
        items:[
            {name: 'нет', value:"0"},
            {name:  'сп', value:"1"},
            {name:'смз сборщик', value:"2"},
            {name: 'аутсорс сборщик', value:"3"},
            {name: 'сборщик', value:"4"},
            {name:  'ртз', value:"5"},
            {name: 'лёгкий труд', value:"6"},
            {name: 'почасовик вечерний', value:"7"},
            {name:  'несборщик', value:"8"},
            {name:  'сккн', value:"9"},
            {name:  'товаровед', value:"10"},
        ]
    },
    {
        propertyName:'fio',
        name:'ФИО',
        rights:'admin',
        type:'text',
        value:''
    },
    {
        propertyName:'description',
        name:'Описание',
        type:'text',
        value:''
    },
    {
        propertyNameStart:'plan_start',
        propertyNameEnd:'plan_end',
        name:'График работы',
        rights:'admin',
        type:'timeRange',
        value:''
    },
]

const stores={
    '1':'новая 10',
    '2':'Авангардная 16',
    '3':'Улица Большая Академическая, 24 к2',
    '4':'Черняховского 6',
    '5':'Часовая 18',
    '1754':'г.Королев. просп.Космонавтов, 4г',
    '4755':'4755 Пятницкое 29',
    '4049' : "4049 Бутырская 2"
}
const notify_codes={
    "0" : "Обнаружен подозрительный посетитель",
    "1" : "Выход вора",
    "3" : "Сотрудник вошёл",
    "4" : "Сотрудник вышел",
    "5" : "Опоздание сотрудника",
    "6" : "Выход сотрудника раньше графика",
    "7" : "Выход сотрудника позже графика",
    "8" : "Сотрудник вышел не в свою смену",
    "9" : "Не указан график сотрудника",
    "10" : "Отсутствие сотрудников на кассе более 5 минут",
    "11" : "Отсутствие сотрудников на кассе более 1 минуты, при наличии очереди",
    "12" : "Посторонний человек за кассой",
    "13" : "Сотрудник обнаружен",
    "14" : "Сотрудник не состоит в базе, добавьте пожалуйста сотрудника",
    "15" : "Отсутствие сотрудника боле часа в смену"
}
const AddEmployeeForm = (props) => {

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(true);

    let navigate = useNavigate();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const submit = async (output)=>{
        console.log('submit', output)
        setLoading(true)
        const res = await RestaurantService.merge(navigate,output)
        setLoading(false)
        if (res.status == 'error'){
            console.error(res.message)
            //dispatch(showError(res.message))
        }
        handleClose();
    }

    return <div >
        <div  >
            <IconUserAddLine onClick={handleShow} style={{marginLeft:'auto'}} fill={'white'} color={'white'} width={'48px'} height={'25px'}/>
        </div>
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Добавить сотрудника</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <FormBuilder fields={fields} buttonName={'Применить'}  submit={submit}/>
        </Modal.Body>

    </Modal>
    </div>
}
export default AddEmployeeForm
