import {createSlice} from "@reduxjs/toolkit"


const errorSlice = createSlice({
    name:'error',
    initialState:{
        errors:[],
    },
    reducers:{
        showError(state, action){
            state.errors.push(action.payload)
        },
        hideError(state, action){
            console.log(action.payload)
            state.errors.splice(action.payload, 1)
        },
        hideAllError(state){
            state.errors = []
        }
    }
})

export default errorSlice.reducer
export const {showError,hideError,hideAllError} = errorSlice.actions
