import React, {useState, useEffect} from "react";

import './itemCard.css';
import Modal from 'react-bootstrap/Modal';
import FormBuilder from "./form";
import {useNavigate} from "react-router-dom";
import IconPencilSquare from "../icons/editIcon";
import {loginAction} from "../redux/authSlice";
import {showError} from "../redux/errorSlice";
import AuthService from "../api/authService";
import {useDispatch} from "react-redux";
import UsersService from "../api/usersService";
import DronesService from "../api/dronesService";
import {Button} from "react-bootstrap";

const fields = [

    // {
    //     propertyName:'phone',
    //     name:'Телефон',
    //     type:'text',
    //     value:''
    // },
    {
        propertyName:'name',
        name:'Название',
        type:'text',
        value:''
    },
    {
        propertyName:'number',
        name:'Номер',
        type:'text',
        value:''
    },
    {
        propertyName:'vpnAddress',
        name:'Адрес VPN',
        type:'text',
        value:''
    },

]
const DroneEditModalForm = (props) => {

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    let navigate = useNavigate();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const submit = async (output)=>{
        console.log('submit', output)
        setLoading(true)
        let body = {
            name: output.name,
            number: output.number,
            vpnAddress: output.vpnAddress,
        }
        console.log(props.values)
        if(props.values?._id) body['_id'] = props.values._id
        const result = await DronesService.edit( navigate, body)

        setLoading(false)
        if(result.status == 'OK'){
            handleClose();
            props.refresh();
        }
        else if(result.status == 'error') {
            if(result.errors){
                result.errors.forEach((error)=>dispatch(showError(error.msg)))
            }else
                dispatch(showError(result.message))
        }

    }

    return <div >
        <div  >
            {props.values && <IconPencilSquare onClick={handleShow} style={{cursor:'pointer'}} fill={'white'} color={'white'} width={'20px'} />}
            {!props.values && <Button style={{marginBottom:20}} variant="secondary" onClick={handleShow} >+ Добавить устройство</Button>}

        </div>
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>{props.values ? 'Изменить данные устройства' : 'Добавить данные устройства'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <FormBuilder fields={fields} values={props.values} buttonName={'Сохранить'}  submit={submit}/>
        </Modal.Body>

    </Modal>
    </div>
}
export default DroneEditModalForm
