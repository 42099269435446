export const getPointsFromActions = (actions) => {
    let points = []
    actions.forEach((action, i) => {
        let {cmd, ...point} = action
        if(cmd !== "takeoff"){
            if(cmd === "servo") points[points.length-1]["isTarget"] = true;
            else points.push(point)
        }
    })
    console.log(points);
    return points
}
export const getActionsFromPoints = (points) => {
    let actions = []
    let zero_point = {lat:points[0].lat, lon: points[0].lon, alt: points[0].alt}

    actions = [{
        "cmd": "takeoff",
        "alt": zero_point.alt
    }]

    points.forEach((point,index)=>{
        actions.push({
            ...point,
            cmd:points.length != index+1 ? "point":"land",
        })
        if(point.isTarget) actions.push({
            "cmd": "servo",
            "id": 1,
            "servo": 1500
        })
    })
    return {actions, zero_point}
}
export function getDistanceFromLatLonInKm(point1, point2) {

    const R = 6378137; // Radius of the earth in km
    const dLat = deg2rad(point2.lat-point1.lat);  // deg2rad below
    const dLon = deg2rad(point2.lon-point1.lon);
    let a =
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(deg2rad(point1.lat)) * Math.cos(deg2rad(point2.lat)) *
        Math.sin(dLon/2) * Math.sin(dLon/2)
    ;
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    let d = (R * c / 1000).toFixed(2); // Distance in km
    return d;
}
export function getDistanceFromLatLon(point1, point2) {

    const R = 6378137; // Radius of the earth in km
    const dLat = deg2rad(point2.lat-point1.lat);  // deg2rad below
    const dLon = deg2rad(point2.lon-point1.lon);
    let a =
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(deg2rad(point1.lat)) * Math.cos(deg2rad(point2.lat)) *
        Math.sin(dLon/2) * Math.sin(dLon/2)
    ;
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    let d = R * c / 1000; // Distance in km
    return d;
}
export function getLatLonFromDistance(point1, point2) {
    let coords = {}
    const R = 6378137; // Radius of the earth in km
    const dLat = deg2rad(point1.lat);  // deg2rad below
    const dLon = deg2rad(point1.lon);
    const dLatM = point2.lat/R
    const dLonM = point2.lon/(R*Math.cos(Math.PI*point1.lat/180))*-1
    coords['lat'] = rad2deg(dLat+dLatM)
    coords['lon'] = rad2deg(dLon+dLonM)

    return coords;
}
function deg2rad(deg) {
    return deg * (Math.PI/180)
}
function rad2deg(rad) {
    return rad * (180/Math.PI)
}


export function getCoordsByAzimuth(azimuth, distance, initial_position){
    console.log(initial_position)
    const azimuth_rad = deg2rad(azimuth);
    const EARTH_RADIUS = 6378137;
    const d = distance/EARTH_RADIUS;


    const init_lat = deg2rad(initial_position.lat)
    const init_lon = deg2rad(initial_position.lon);
    const latRad = Math.asin( (Math.sin(init_lat)*Math.cos(d) + Math.cos(init_lat)*Math.sin(d)*Math.cos(azimuth_rad)));
    const lonRad = init_lon + Math.atan2((Math.sin(azimuth_rad)*Math.sin(d)*Math.cos(init_lat)), (Math.cos(d)-Math.sin(init_lat)*Math.sin(latRad)));
    const lat =  rad2deg(latRad);
    const lon =  rad2deg(lonRad);
    console.log('distance', getDistanceFromLatLonInKm({lat, lon}, initial_position ));

    return { lat, lon }
}
