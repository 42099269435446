import React, {useEffect, useMemo, useRef, useState} from "react";
import { Button, YMaps, Map, Placemark, Polyline, FullscreenControl, TypeSelector, RulerControl } from '@pbe/react-yandex-maps';

import '../../../components/itemCard.css';

import CurrentPosition from "../../../components/Map/currentPosition";
const DroneMap = ({drone_number}) => {

    return <div>
        <div>
            <YMaps>
                <Map
                    options={{dragCursor: "arrow", fullscreenZIndex: 50,}}
                    className={"map"}
                    width={'100%'}
                    height={500}
                    defaultState={{ center: [49.138505, 32.697259], zoom: 5 }}
                >

                    <CurrentPosition drone_number={drone_number} targetPoint={null} />
                    <FullscreenControl />
                    <TypeSelector options={{ float: "right" }} />
                    <RulerControl options={{ float: "right" }} />
                </Map>
            </YMaps>
        </div>
    </div>
}
export default DroneMap
