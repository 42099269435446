import {useEffect, useState} from "react";
import * as React from "react";
import Form from 'react-bootstrap/Form';

export default function TextAreaCustomInput(props) {
    const [lang, setLang] = useState('en');
    const [valueLang, setValueLang] = useState('en');

    useEffect(()=>{
        console.log('props.value', props.value)
        setValueLang(props.value)
    },[props.value])
    useEffect(()=>{
        console.log('valueLang',valueLang)

    },[valueLang])
    const setValueLangFunc = (value)=>{
        let valueLangCopy = {...valueLang}
        valueLangCopy[lang] = value
        setValueLang(valueLangCopy)
        props.onChange(lang, value)
    }
    const setValueFunc = (value)=>{
        setValueLang(value)
        props.onChange( value)
    }
    // <TouchableOpacity onPress={()=>{lang == 'en' ? setLang('ru'): setLang('en')}}>
    //     <View style={{flexDirection:'row'}}>
    //         <Text style={lang == 'en' ? styles.lang:null}>en</Text>
    //         <Text> / </Text>
    //         <Text style={lang == 'ru' ? styles.lang:null}>ru</Text>
    //     </View>
    // </TouchableOpacity>
      return (
          <div  style={{marginBottom:10}}>
              <Form.Label>{props.label}</Form.Label>
              <Form.Control
                  as={props.type}
                  defaultValue={props.value}
                  onChange={(event) => {
                      setValueLangFunc(event.target.value)
                  }}
              />
          </div>
      );
}

