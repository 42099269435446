import React, {useState, useEffect} from "react";

import './itemCard.css';
import Modal from 'react-bootstrap/Modal';
import FormBuilder from "./form";
import {useNavigate} from "react-router-dom";
import IconPencilSquare from "../icons/editIcon";
import {loginAction} from "../redux/authSlice";
import {showError} from "../redux/errorSlice";
import AuthService from "../api/authService";
import {useDispatch} from "react-redux";
import UsersService from "../api/usersService";

const fields = [
    {
        propertyName:'email',
        name:'E-mail',
        type:'text',
        value:''
    },
    // {
    //     propertyName:'phone',
    //     name:'Телефон',
    //     type:'text',
    //     value:''
    // },
    {
        propertyName:'name',
        name:'Позывной',
        type:'text',
        value:''
    },
    {
        propertyName:'password',
        name:'Пароль',
        type:'password',
        value:''
    },
    {
        propertyName:'repeatPassword',
        name:'Повторите пароль',
        type:'password',
        value:''
    }

]
const AccountEditModalForm = (props) => {

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    let navigate = useNavigate();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const submit = async (output)=>{
        console.log('submit', output)
        setLoading(true)
        let body = {
            birthday: output.birthday,
            name: output.name,
            email: output.email,
            country: output.country,
            aboutme: output.aboutme
        }
        if(output.password) body['password'] = output.password
        if(output.photo_uri)  body['photo_uri'] = output.photo_uri
        const result = await UsersService.edit( navigate, body)

        setLoading(false)
        if(result.status == 'OK'){
            dispatch(loginAction(result.userProfile))
            handleClose();
        }
        else if(result.status == 'error') {
            if(result.errors){
                result.errors.forEach((error)=>dispatch(showError(error.msg)))
            }else
                dispatch(showError(result.message))
        }

    }

    return <div >
        <div  >
            <IconPencilSquare onClick={handleShow} style={{marginLeft:'30', marginTop:8, cursor:'pointer'}} fill={'white'} color={'white'} width={'35px'} />
        </div>
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Изменить данные аккаунта</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <FormBuilder fields={fields} values={props.values} buttonName={'Применить'}  submit={submit}/>
        </Modal.Body>

    </Modal>
    </div>
}
export default AccountEditModalForm
