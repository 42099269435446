import React, {useCallback, useEffect, useState} from "react";
import AccountEditModalForm from "../components/accountEditModalForm";

import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import {useSelector} from "react-redux";
import EcoService from "../api/ecoService";
import Card from 'react-bootstrap/Card';
import config from "../config.json"
import Image from "react-bootstrap/Image";
import '../style.css'
import FootPrintList from "../components/FootPrintList";
const moment = require('moment');
const countries = {'RU': 'Russia',
    'CN': 'China',
    'IN': 'India'}

export default function Account() {
    const auth = useSelector(state => state.auth)
    const [results, setResults] = useState([])
    let navigate = useNavigate();
    const getResults = async () =>{
        const res = await EcoService.get(navigate, {}) //
        if (res.status == "OK"  &&  res.items) {
            console.log(res)
            setResults(res.items)
        }
    }


    useEffect(()=>{
        getResults()
    },[])
    return <Container>
        <div style={{display:'flex', flexDirection:'row'}}  className='h'><h1>Учетная запись</h1><AccountEditModalForm values={auth.userProfile} /></div>
        <div style={{display:'flex', flexDirection:'row'}}>
            <div>
                {auth.userProfile && <div className="accountItem">Позывной: {auth.userProfile.name}</div>}
                {auth.userProfile && <div className="accountItem">Email: {auth.userProfile.email}</div>}
            </div>
        </div>
    </Container>
}

