import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';

import Table from "react-bootstrap/Table";
import '../style.css'
import {Button} from "react-bootstrap";
import IconMapLocationDot from "../icons/map";
import DetectorsService from "../api/detectorsService";
export default function Detectors() {
    const [detectors, setDetectors] = useState([])
    let navigate = useNavigate();
    useEffect(()=>{
        getDetectors()
    },[])

    const getDetectors = async () =>{
        const res = await DetectorsService.get(navigate)
        if (res.status == "OK"  &&  res.items) {
            setDetectors(res.items)
        }
    }
    const Row = ({detector, i})=>{
        return <tr>
            <td>{i+1}</td>
            <td>{detector.name}</td>
            <td>{detector.type}</td>
            <td>{detector.number}</td>
            <td>{detector.connected_detector_id}</td>
            <td>
                <IconMapLocationDot onClick={() => goToDetectorPage(detector._id)} style={{cursor:'pointer'}} fill={'white'} color={'white'} width={'20px'} />

                {/*<MissionMapModalForm actions={props.mission.actions} />*/}
            </td>
        </tr>
    }
    const goToDetectorPage = (detectorId) => {
        navigate('/detector/' + detectorId)
    }
    return <Container>
        <h1 className="h">Детекторы</h1>
        <Button style={{marginBottom:20}} variant="secondary" onClick={() => goToDetectorPage("")} >+ Добавить детектор</Button>
        <Table striped bordered hover>
            <thead>
            <tr>
                <th>#</th>
                <th>Название</th>
                <th>Тип</th>
                <th>Номер устройства</th>
                <th>Связанный детектор</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {detectors.map((detector,i)=><Row detector={detector} i={i} key={detector._id}/>)}
            </tbody>
        </Table>

    </Container>
}

