import React from "react";
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Layout } from "./layout/layout";
import { Provider } from 'react-redux';
import store from "./redux"
import Router from "./router";
function App() {

  return (
      <Provider store={store}>
          <BrowserRouter basename='/'>
            <Layout>
              <Router />
            </Layout>
          </BrowserRouter>
      </Provider>
  );
}

export default App;
