import fetchData from "./fetchData";
import api from "./api";

export default class MissionsService {
  static async get(navigation) {
    const res = await fetchData(api.getMissions, navigation, 'get')
    return res
  }
  static async getMission(navigation, id) {
    const res = await fetchData(api.getMission, navigation, 'get', id)
    return res
  }
  static async getPosition(navigation, drone_number) {
    //const res = await fetchData(api.getDroneStatus, navigation, 'get', drone_number)
    const res = await fetchData(api.getDronePosition, navigation, 'get', drone_number)
    return res
  }
  static async delete(navigation, id) {
    const res = await fetchData(api.deleteMission, navigation, 'get',id)
    return res
  }
  static async merge(navigation, body) {
    const res = await fetchData(api.mergeMission, navigation, 'post', {...body})
    return res
  }
}
