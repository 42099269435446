import React from "react";
import { Routes, Route} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Auth from '../pages/Auth'
import Registration from "../pages/Registration";
import Account from "../pages/Account";
import Drones from "../pages/Drones";
import Missions from "../pages/Missions";
import Index from "../pages/mission";
import Test from "../pages/Test";
import Detectors from "../pages/Detectors";
import Detector from "../pages/detector";
import Drone from "../pages/drone";
import Emulators from "../pages/Emulators";
import Emulator from "../pages/emulator";

function Router() {

  return (
          <Routes>
            <Route path="/" element={<Drones />} />
            <Route path="*" element={<Drones />} />

            <Route path="login" element={<Auth />} />
            <Route path="registration" element={<Registration />} />

            <Route path="test" element={<Test />} />

            <Route path="account" element={<Account />} />

            <Route path="drones" element={<Drones />} />
            <Route path="drone/:droneId" element={<Drone />} />

            <Route path="missions" element={<Missions />} />
            <Route path="mission/:missionId" element={<Index />} />
            <Route path="mission" element={<Index />} />
            <Route path="emulators" element={<Emulators />} />
              <Route path="emulator/:emulatorId" element={<Emulator />} />
              <Route path="emulator" element={<Emulator />} />

            <Route path="detectors" element={<Detectors />} />
            <Route path="detector/:detectorId" element={<Detector />} />
            <Route path="detector" element={<Detector />} />
          </Routes>
  );
}

export default Router;
