import fetchData from "./fetchData";
import api from "./api";

export default class DetectorsService {
  static async get(navigation) {
    const res = await fetchData(api.getDetectors, navigation, 'get')
    return res
  }
  static async cleanTargets(navigation, id) {
    const res = await fetchData(api.cleanTargets, navigation, 'get', id)
    return res
  }
  static async selectTarget(navigation, body, id) {
    const res = await fetchData(`${api.selectTarget}/${id}`, navigation, 'post', body)
    return res
  }
  static async selectPredator(navigation, body, id) {
    const res = await fetchData(`${api.selectPredator}/${id}`, navigation, 'post', body)
    return res
  }
  static async getDetector(navigation, id) {
    const res = await fetchData(api.getDetector, navigation, 'get', id)
    return res
  }
  static async getTargets(navigation, id) {
    const res = await fetchData(api.getTargets, navigation, 'get', id)
    return res
  }
  static async merge(navigation, body) {
    const res = await fetchData(api.mergeDetector, navigation, 'post', {...body})
    return res
  }
  static async delete(navigation, id) {
    const res = await fetchData(api.deleteDetector, navigation, 'get',id)
    return res
  }

}
