import {useNavigate} from "react-router-dom";
import AuthService from "../api/authService";
import AddEmployeeForm from "../components/addEmplyeeForm";
import IconLogout from "../icons/logout";
import IconLeaf from "../icons/leaf";

import React, {useEffect, useState} from "react";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {useDispatch, useSelector} from "react-redux";
import Errors from '../components/errors'
import {loginAction, logoutAction} from "../redux/authSlice";
import IconLoginBoxLine from "../icons/login";
import Cookies from "js-cookie";
import Image from "react-bootstrap/Image";
import config from "../config.json";
import Navigator from "./navigator";


export function Layout (props) {
    const auth = useSelector(state => state.auth)
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const expand = false;
    const [show, setShow] = useState(false)
    const goToLogin =  ()=>{
        navigate('/login')
    }
    const goToAccount =  ()=>{
        navigate('/account')
    }
    useEffect(()=>{
        if(!auth.userProfile){
            const userProfile = Cookies.get("userProfile")
            if(userProfile)
                dispatch(loginAction(JSON.parse(userProfile)))
            else{
                goToLogin()
            }
        }
    },[auth])
    const logout = async ()=>{
        const result = await AuthService.logout(navigate)
        if(result){
            dispatch(logoutAction())
            //dispatch(dropShippingAddress())
        }
    }
    return (
        <div style={{ minHeight:'100%'}}>
            <Errors />
            {auth.userProfile && <Navigator />}
            <div style={{maxWidth:'700px',marginLeft:'auto',marginRight:'auto'}}>
                {props.children}
            </div>
        </div>
    );
}
