import React, {useState, useEffect} from "react";

import Modal from 'react-bootstrap/Modal';
import FormBuilder from "../../../components/form";

const fields = [
    {
        propertyName:'lat',
        name:'Долгота',
        type:'text',
        value:''
    },
    {
        propertyName:'lon',
        name:'Широта',
        type:'text',
        value:''
    },
    {
        propertyName:'alt',
        name:'Высота',
        type:'text',
        value:''
    },
]
const PointEditModalForm = ({ point, onSubmit, onClose, show, setShow, editMode }) => {

    return <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton>
            <Modal.Title>{ editMode ? 'Изменить данные точки' : "Просмотр данных точки" }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <FormBuilder fields={fields} values={point} buttonName={ 'Сохранить'  }  submit={onSubmit}/>
        </Modal.Body>
    </Modal>
}
export default PointEditModalForm
