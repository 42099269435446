import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import EcoService from "../api/ecoService";
import UsersService from "../api/usersService";
import moment from 'moment'

import Table from "react-bootstrap/Table";
import '../style.css'
import MissionsService from "../api/missionsService";
import MissionMapModalForm from "../components/missionMapModalForm";
import DroneEditModalForm from "../components/droneEditModalForm";
import MissionEditModalForm from "../components/missionEditModalForm";
import {Button} from "react-bootstrap";
import IconMapLocationDot from "../icons/map";
export default function Missions() {
    const [missions, setMissions] = useState([])
    let navigate = useNavigate();
    useEffect(()=>{
        getMissions()
    },[])

    const getMissions = async () =>{
        const res = await MissionsService.get(navigate)
        if (res.status == "OK"  &&  res.items) {
            setMissions(res.items)
        }
    }
    const Row = (props)=>{
        return <tr>
            <td>{props.i+1}</td>
            <td>{props.mission.name}</td>
            <td>{moment(props.mission.date).format('YYYY-MM-DD HH:mm:ss')}</td>
            <td>{props.mission.drone_number}</td>

            <td>
                <IconMapLocationDot onClick={() => goToMissionPage(props.mission._id)} style={{cursor:'pointer'}} fill={'white'} color={'white'} width={'20px'} />

                {/*<MissionMapModalForm actions={props.mission.actions} />*/}
            </td>
        </tr>
    }
    const goToMissionPage = (missionId) => {
        navigate('/mission/' + missionId)
    }
    return <Container>
        <h1 className="h">Полетные задания</h1>
        <Button style={{marginBottom:20}} variant="secondary" onClick={() => goToMissionPage("")} >+ Создать ПЗ</Button>
        <Table striped bordered hover>
            <thead>
            <tr>
                <th>#</th>
                <th>Название</th>
                <th>Дата</th>
                <th>Номер устройства</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {missions.map((mission,i)=><Row mission={mission} i={i} key={mission._id} />)}
            </tbody>
        </Table>

    </Container>
}

