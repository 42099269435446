import fetchData from "./fetchData";
import api from "./api";

export default class DronesService {
  static async get(navigation) {
    const res = await fetchData(api.getDrones, navigation, 'get')
    return res
  }
  static async getDrone(navigation, id) {
    const res = await fetchData(api.getDrone, navigation, 'get', id)
    return res
  }
  static async getDroneByNumber(navigation, number) {
    const res = await fetchData(api.getDroneByNumber, navigation, 'get', number)
    return res
  }
  static async getPosition(navigation, drone_number) {
    const res = await fetchData(api.getDronePosition, navigation, 'get', drone_number)
    return res
  }
  static async delete(navigation, id) {
    const res = await fetchData(api.deleteDrone, navigation, 'get',id)
    return res
  }
  static async edit(navigation, body) {
    const res = await fetchData(api.droneEdit, navigation, 'post', {...body})
    return res
  }
}
