import React, {useEffect, useState} from "react";
import Container from 'react-bootstrap/Container';
import '../../style.css'
import {useParams} from "react-router";
import MissionMap from "../../components/missionMap";
import MissionsService from "../../api/missionsService";
import {useNavigate} from "react-router-dom";
import VideoByImage from "../../components/videoByImage";

export default function Mission() {
    const { missionId } = useParams()
    const [mission, setMission] = useState()

    let navigate = useNavigate();

    useEffect(()=>{
        if (missionId) getMission(missionId)
    },[missionId])

    useEffect(()=>{
        console.log(mission)
    },[mission])

    const getMission = async (id) =>{
        const res = await MissionsService.getMission(navigate, id)
        if (res.status == "OK"  &&  res.item) {
            setMission(res.item)
        }
    }

    return <Container>
        {!missionId && <h1 className="h">Создание миссии</h1>}
        {/*{mission && mission.drone_number && mission.status !== "new" && <DroneVideo droneNumber={mission.drone_number} />}*/}
        {mission && mission.drone_number && mission.status !== "new" && <VideoByImage number={mission.drone_number} />}

        <MissionMap mission={mission} setMission={setMission} />
    </Container>
}

