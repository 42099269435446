import {useEffect, useState} from "react";
import * as React from "react";
import Form from 'react-bootstrap/Form';

export default function BooleanInput(props) {
    const [isSwitchOn, setIsSwitchOn] = React.useState(false);
    useEffect(()=>{
        setIsSwitchOn(props.value)
        console.log('props.value SHEKBOX',props.value)
    },[props.value])
    useEffect(()=>{
    if(props.onChange) props.onChange(isSwitchOn)
    },[isSwitchOn])

    return  <Form.Check // prettier-ignore
        type="switch"
        checked={isSwitchOn}
        onClick={(event)=>{if(props.onClick) props.onClick(event.target.checked)}}
        onChange={(event)=>setIsSwitchOn(event.target.checked)}
        id={props.key}
        label={props.label}
    />
}


