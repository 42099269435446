import {Button} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import DronesService from "../../api/dronesService";
import {useNavigate} from "react-router-dom";
import Select from "../form/select";
import TextCustomInput from "../form/textInput";
import MissionsService from "../../api/missionsService";
import {showError} from "../../redux/errorSlice";
import {getActionsFromPoints} from "../../utils/mapHelpers";
import {useDispatch} from "react-redux";

const statusOptions = [
    {value: "new", name: "Новая"},
    {value: "sent", name: "Отправлена"},
    {value: "success", name: "Завершена успешно"},
    {value: "failure", name: "Завершена не успешно"},
    {value: "finish", name: "Завершена"}
]

const Controls = ({ children, mission, editMode, onChangeEditMode, points }) => {
    const [dronesOptions, setDronesOptions] = useState([])
    const [selectedDrone, setSelectedDrone] = useState();
    const [title, setTitle] = useState("Новая миссия");
    const [selectedStatus, setSelectedStatus] = useState("new");
    const [loading, setLoading] = useState(false);
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const onEdit = () => onChangeEditMode(true)
    const onSave = () => {
        save()
    }
    const onSend = async () => {
        if (points.length < 2){
            dispatch(showError("Укажите не менее двух точек"))
        } else if(!selectedDrone) {
            dispatch(showError("Не выбрано устройство"))
        }else {
            const actionsAndZeroPoint = getActionsFromPoints(points)
            setLoading(true)
            let body = {
                ...mission,
                name: title,
                drone_name: getDroneName(),
                drone_number: selectedDrone,
                status: selectedStatus,
                ...actionsAndZeroPoint,
                through: 0,
                mode: 'shturm',
            }
            console.log('send mission', body)
            // const result = await MissionsService.merge( navigate, body)
            // setLoading(false)
            //
            // if(result.status == 'OK'){
            //     onChangeEditMode(false)
            // }
            // else if(result.status == 'error') {
            //     if(result.errors){
            //         result.errors.forEach((error)=>dispatch(showError(error.msg)))
            //     }else
            //         dispatch(showError(result.message))
            // }
        }
    }
    const onCancel = () => {
        onChangeEditMode(false)
    }
    const getDroneName = () => {
        const option = dronesOptions.find((item) => item.value === selectedDrone)
        return option?.name
    }
    const getDroneStatus = () => {
        const option = statusOptions.find((item) => item.value === selectedStatus)
        return option?.name
    }
    const save = async ()=>{
        console.log('submit', points, selectedDrone, title, selectedStatus)
        if (points.length < 2){
            dispatch(showError("Укажите не менее двух точек"))
        } else if(!selectedDrone) {
            dispatch(showError("Не выбрано устройство"))
        }else {
            const actionsAndZeroPoint = getActionsFromPoints(points)
            setLoading(true)
            let body = {
                ...mission,
                name: title,
                drone_name: getDroneName(),
                drone_number: selectedDrone,
                status: selectedStatus,
                ...actionsAndZeroPoint,
                through: 0,
                mode: 'shturm',
            }

            const result = await MissionsService.merge( navigate, body)
            setLoading(false)

            if(result.status == 'OK'){
                onChangeEditMode(false)
            }
            else if(result.status == 'error') {
                if(result.errors){
                    result.errors.forEach((error)=>dispatch(showError(error.msg)))
                }else
                    dispatch(showError(result.message))
            }
        }
    }

    const onChangeDrone = (value) => setSelectedDrone(value)
    const onChangeStatus = (value) => setSelectedStatus(value)

    useEffect(()=>{
        if (mission) {
            setSelectedDrone(mission.drone_number)
            setSelectedStatus(mission.status)
            setTitle(mission.name)
        }
    },[mission])
    useEffect(()=>{
        console.log('selectedDrone', selectedDrone)
    },[selectedDrone])
    useEffect(()=>{
        if(editMode) getDrones()
    },[editMode])

    // useEffect(()=>{
    //     if((!mission || !mission.droneId) && dronesOptions && dronesOptions[0]) setSelectedDrone(dronesOptions[0].value)
    // },[dronesOptions])
    const onBack = () => navigate(-1)
    const getDrones = async () =>{
        const res = await DronesService.get(navigate)
        if (res.status == "OK"  &&  res.items) {
            setDronesOptions(res.items.map((item) => ({
                value: item.number,
                name: item.name
            })));
        }
    }
    return <div>
        <div style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 20,
            gap: 10,
        }}>
            {editMode && <>
                    <TextCustomInput onChange={setTitle} value={ title } required />
                    <Select items={dronesOptions} onChange={onChangeDrone} value={selectedDrone} required />
                    <Select items={statusOptions} onChange={onChangeStatus} value={selectedStatus} required />
                    <Button variant="secondary" onClick={onSave} >Сохранить</Button>
                <Button variant="secondary" onClick={onCancel} >Отменить</Button>
                </>
            }
            {!editMode &&
            <>
                <Button variant="secondary" onClick={onBack}>Назад</Button>
                <div style={{lineHeight:"37px"}}>{title}</div>
                <div style={{lineHeight:"37px"}}>{getDroneName()}</div>
                <div style={{lineHeight:"37px"}}>{getDroneStatus()}</div>
                <Button variant="secondary" onClick={onEdit} style={{marginLeft: 'auto'}}>Редактировать</Button>
                {selectedStatus === 'new' && <Button variant="secondary" onClick={onSend}>Отправить</Button>}
            </>}
        </div>
        {children}
    </div>
}
export default Controls
