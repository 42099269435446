import fetchData from "./fetchData";
import api from "./api";
import Cookies from "js-cookie";

export default class UsersService {
  static async get(navigation) {
    const res = await fetchData(api.getUsers, navigation, 'get')
    return res
  }
  static async edit(navigation, body) {
    const res = await fetchData(api.userEdit, navigation, 'post', {...body})
    if(res && res.userProfile){
      let d = new Date();
      d.setTime(d.getTime() + (24*60*60*1000));
      Cookies.set("userProfile", JSON.stringify(res.userProfile), {path: "/", expires: d});
    }
    return res
  }
}
