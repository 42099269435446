import {useEffect, useState} from "react";
import * as React from "react";
import Form from 'react-bootstrap/Form';
import BooleanInput from "./booleanInput";


export default function BooleanGroup(props) {
    const [value, setValue] = React.useState([]);
    useEffect(()=>{
        setValue(props.value)
        console.log('props.value SHEKBOX Group',props.value)
    },[props.value])

    useEffect(()=>{
    props.onChange(value)
    },[value])
    const onChange = (key,Inputvalue)=>{
        console.log('onChange',key,Inputvalue)
        let output = [...value]
        let index = output.indexOf(key)
        if(index === -1 && Inputvalue) output.push(key)
        else if(!Inputvalue) output.splice(index, 1);
        setValue(output)
    }
    const onAll = ()=>{
        let output = []
        props.items.forEach((key)=>{
            console.log(key.value)
            output.push(key.value)
        })
        setValue(output)
    }
    return  <div>
        {props.items && <BooleanInput
            value={value.length === props.items.length}
            label={'Все'}
            onClick={(itemValue) => itemValue ? onAll():setValue([])} />}
        {props.items && props.items.map((item)=> <BooleanInput
                          value={value && value.indexOf(item.value) != -1}
                          label={item.name}
                          onChange={(itemValue) => {
                             onChange(item.value,itemValue)
                          }} />)}
    </div>
}


