import {useEffect, useRef, useState} from "react";
import Form from 'react-bootstrap/Form';
import * as React from "react";
import Spinner from 'react-bootstrap/Spinner';

import config from "../../config.json"
import IconPhotoPlus from "../../icons/addPhoto";


export default function ImageInput(props) {
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState()
    const [fileUri, setFileUri] = useState("");
    const reader = new FileReader();
    const inputRef = useRef(null);
    reader.addEventListener(
        "load",
        () => {
            // convert image file to base64 string
            setFileUri(reader.result)
            let res = reader.result.split(';base64,')
            console.log('setFileUri')
            props.setFileUri(res[1])
        },
        false,
    );
    const imgOnClickHandler = ()=>{
        inputRef.current.click()
    }
    const handleFile = event => {
        if (event.target.files[0]) {
            console.log(event.target.files[0])
            reader.readAsDataURL(event.target.files[0])
        }else{
            setFileUri("")
        }
    };


    useEffect(()=>{
        if(props.value) setFileUri(config.filesPath + props.value)
    },props.value)

      return (
          <div style={{marginBottom:20}}>
              <Form.Label>{props.label}</Form.Label>
              <div>
                  {!loading && fileUri && <img onClick={imgOnClickHandler} style={{ height: 200, maxWidth:400}} src={fileUri} />}
                  {!loading && !fileUri && <IconPhotoPlus onClick={imgOnClickHandler} height={100} />}
                  {loading && <div style={{marginTop: 20}}> <Spinner animation="border" variant="primary" /></div>}
              </div>
              <Form.Control
                  style={{visibility: 'hidden', display:'none'}}
                  type="file"
                  onChange={handleFile}
                  ref={inputRef}
              ></Form.Control>
          </div>
      );
}


