import fetchData from "./fetchData";
import api from "./api";
import Cookies from "js-cookie";

export default class AuthService{

  static async login( body){
    console.log(body)
    const res = await fetchData( api.userLogin,null, 'post',{...body})
    if(res && res.tokens && res.tokens.access_token) {
      let d = new Date();
      d.setTime(d.getTime() + (24*60*60*1000));
      Cookies.set("accessToken", res.tokens.access_token, {path: "/", expires: d});
      Cookies.set("refreshToken", res.tokens.refresh_token, {path: "/", expires: d});
      Cookies.set("userProfile", JSON.stringify(res.userProfile), {path: "/", expires: d});
      // await AsyncStorage.setItem('accessToken', res.tokens.access_token)
      // await AsyncStorage.setItem('refreshToken', res.tokens.refresh_token)
      // await AsyncStorage.setItem('userProfile', JSON.stringify(res.userProfile))
      return res;
    }
    else{
      return res;
    }
  }
  static async sendRecoveryCode(navigation, body){
    const res = await fetchData( api.sendRecoveryCode, navigation, 'post',{...body})
    return res;
  }
  static async checkRecoveryCode(navigation, body){
    const res = await fetchData( api.checkRecoveryCode, navigation, 'post',{...body})
    return res;
  }
  static async changePassword(navigation, body){
    const res = await fetchData( api.changePassword, navigation, 'post',{...body})
    return res;
  }
  static async activate(navigation, body){
    const res = await fetchData( api.userActivate,navigation, 'post',{...body})
    console.log(res)
    if(res.code === 0)
      return true
    else
      return false
  }
  static async logout(navigation){
    const res = await fetchData(api.userLogout, 'get')
    if(res) {
      Cookies.remove('accessToken')
      Cookies.remove('refreshToken')
      Cookies.remove('userProfile')

      // await AsyncStorage.removeItem('accessToken');
      // await AsyncStorage.removeItem('refreshToken');
      // await AsyncStorage.removeItem('userProfile');
      navigation('/auth')
      return true;
    }
    return ;

  }
  static async refresh(){
    let res = await fetch(api.authRefresh, {method:'get', credentials: 'include'})
    if(res.status == 401){

    }else {
      res = await res.json()
      console.log(res);
      if (res && res.tokens && res.tokens.access_token) {
        let d = new Date();
        d.setTime(d.getTime() + (24*60*60*1000));
        Cookies.set("accessToken", res.tokens.access_token, {path: "/", expires: d});
        Cookies.set("refreshToken", res.tokens.refresh_token, {path: "/", expires: d});
        // await AsyncStorage.setItem('accessToken', res.tokens.access_token)
        // await AsyncStorage.setItem('refreshToken', res.tokens.refresh_token)
      }
    }
    return;
  }
  static async registration(navigation, body){
    console.log(body.phone)
    if(body.phone) {
      let phone = body.phone
      phone = phone.replace(/[^\d]/g, '')
      if (phone.slice(0, 1) == '7' || phone.slice(0, 1) == '8') {
        phone = phone.substring(1)
      }
      if (phone.length != 10) {

      } else {

      }
      body.phone = phone
    }
    console.log('body', body)
    const res = await fetchData(api.userRegister, navigation,'post',{...body})
    console.log(res)
    if(res && res.tokens && res.tokens.access_token){
      let d = new Date();
      d.setTime(d.getTime() + (24*60*60*1000));
      Cookies.set("accessToken", res.tokens.access_token, {path: "/", expires: d});
      Cookies.set("refreshToken", res.tokens.refresh_token, {path: "/", expires: d});
      Cookies.set("userProfile", JSON.stringify(res.userProfile), {path: "/", expires: d});
      // await AsyncStorage.setItem('accessToken', res.tokens.access_token)
      // await AsyncStorage.setItem('refreshToken', res.tokens.refresh_token)
      // await AsyncStorage.setItem('userProfile', JSON.stringify(res.userProfile))
    }
    return res;
  }
}

