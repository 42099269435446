import {useEffect, useState} from "react";
import * as React from "react";
import Form from 'react-bootstrap/Form';
import DatePicker from "react-datepicker";
import TimePicker from 'react-bootstrap-time-picker';
import "react-datepicker/dist/react-datepicker.css";

import './form.css'
export default function DateTime(props) {
    const [valueDate, setValueDate] = useState('');
    const [valueTime, setValueTime] = useState('');
    useEffect(()=>{
        console.log('props.value', props.value)
        //setValueDate(props.value)
        if(props.value) {
            let propDate = new Date(props.value)
            let testDate = new Date(props.value)
            testDate.setHours(0)
            testDate.setMinutes(0)
            console.log(testDate)
            let timeDiff = propDate.getTime() - testDate.getTime()
            setValueTime(timeDiff / 1000)
            setValueDate(testDate)
        }
    },[props.value])
    useEffect(()=>{
        console.log('valueDate',valueDate)
        console.log('valueTime',valueTime)
        if(valueDate && valueTime) {
            let output = new Date(valueDate.getTime() + valueTime*1000)
            console.log(output)
            props.onChange(output)
        }
    },[valueDate, valueTime])

      return (
          <div  style={{marginBottom:10}}>
              <Form.Label>{props.label}</Form.Label>
              <div style={{display:'flex'}}>
              <DatePicker className={'date-input'} selected={valueDate}  onChange={(date) => setValueDate(date)} />
              <TimePicker value={valueTime} format={24} start="00:00" end="23:59" step={30} onChange={(time) => setValueTime(time)} />
              </div>
          </div>
      );
}
