import Cookies from "js-cookie";
import api from "./api";

//import AsyncStorage from '@react-native-async-storage/async-storage';

async function fetchData(path, navigation, method , body) {
  try {
    let date = new Date()

    console.log('path',path)
    const accessToken = Cookies.get("accessToken")
    console.log('accessToken',accessToken)

    let data = {method: method || "get"}
    if(method == 'post' && body) data['body'] = JSON.stringify(body)
    if(method == 'get' && body) path = path + '/' + body
    //path = path + '?' + date.getTime()

    data['headers'] = {
      'Content-Type': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    }
    if(accessToken) data['headers']['Authorization'] = 'Bearer ' + accessToken

    //data['credentials'] = 'include';
    const response = await fetch(path, data);
    if(response.status == 401 ){

      const refreshToken = await Cookies.get("refreshToken")
console.log('refreshToken',refreshToken)
      let res = await fetch(api.authRefresh, {method:'get', credentials: 'same-origin'})
      console.log('res', res)
        if(res.status == 401){
          navigation('/login')
      }else {
        res = await res.json()
        if (res && res.tokens && res.tokens.access_token) {
          let d = new Date();
          d.setTime(d.getTime() + (24*60*60*1000));
          Cookies.set("accessToken", res.tokens.access_token, {path: "/", expires: d});
          Cookies.set("refreshToken", res.tokens.refresh_token, {path: "/", expires: d});
          return await fetchData(path, navigation, method , body);
        }
      }
    }

    if(response.status == 200 || response.status == 400 ) {
      const responseJson = await response.json()

      if (responseJson.status == 'error') {
        if (responseJson.code == 2) {
          navigation.navigate('Confirm')
        }
      }
      if (responseJson.errors) {
          return responseJson
      } else
        return responseJson;
    }else{
      return {status:'error', message:' fetch error'}
    }
    //
  } catch (error) {
    // eslint-disable-next-line no-console
    return {error: error}
    console.error(error);
  } finally {
    // setLoading(false);
  }
  return [];
}

export default fetchData;
