import React, {useCallback, useEffect, useState} from "react";
import Container from 'react-bootstrap/Container';
import '../../style.css'
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";

import DroneVideo from "../../components/droneVideo";
import VideoByImage from "../../components/videoByImage";
import DetectorsService from "../../api/detectorsService";
import EmulatorMap from "./map/emulatorMap";
import useWebSocket, { ReadyState } from 'react-use-websocket';
import {Button} from "react-bootstrap";
import EmulatorsService from "../../api/emulatorsService";

const Emulator = () => {
    const { emulatorId } = useParams()
    const [emulator, setEmulator] = useState()
        //new WebSocket('ws://89.111.140.84:3000')
    //const { sendMessage, lastMessage, readyState } = useWebSocket('ws://89.111.140.84:3000');
    let navigate = useNavigate();
    // useEffect(() => {
    //     console.log(lastMessage)
    // }, [lastMessage]);

    //const handleClickSendMessage = useCallback(() => sendMessage('Hello'), []);
    useEffect(()=>{
        if (emulatorId) getEmulator(emulatorId)
    },[emulatorId])

    const getEmulator = async (id) =>{
        const res = await EmulatorsService.getEmulator(navigate, id)
        console.log('emulator', res)
        if (res.status == "OK"  &&  res.item) {
            setEmulator(res.item)
        }
    }

    return <Container>
        {!emulatorId && <h1 className="h">Добавить эмулятор</h1>}
        {/*{mission && mission.drone_number && mission.status !== "new" && <DroneVideo droneNumber={mission.drone_number} />}*/}
        {/*{false && detector && <VideoByImage number={mission.drone_number} />}*/}
        {/*<Button onClick={handleClickSendMessage}>SendMessage</Button>*/}

        <EmulatorMap emulator={emulator} setDetector={setEmulator} />
    </Container>
}

export default Emulator
