// icon:radar | Lucide https://lucide.dev/ | Lucide
import * as React from "react";

function IconRadar(props) {
    return (
        <svg
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={props.strokeWidth || 2}
            viewBox="0 0 24 24"
            height="1em"
            width="1em"
            {...props}
        >
            <path d="M19.07 4.93A10 10 0 006.99 3.34M4 6h.01M2.29 9.62a10 10 0 1019.02-1.27M16.24 7.76a6 6 0 10-8.01 8.91M12 18h.01M17.99 11.66a6 6 0 01-2.22 5.01" />
            <path d="M14 12 A2 2 0 0 1 12 14 A2 2 0 0 1 10 12 A2 2 0 0 1 14 12 z" />
            <path d="M13.41 10.59l5.66-5.66" />
        </svg>
    );
}

export default IconRadar;
