// icon:quadcopter | Material Design Icons https://materialdesignicons.com/ | Austin Andrews
import * as React from "react";

function IconQuadcopter(props) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="currentColor"
            height="1em"
            width="1em"
            {...props}
        >
            <path d="M5.5 1C8 1 10 3 10 5.5c0 .88-.25 1.7-.69 2.4l.1.1h5.18l.1-.1c-.44-.7-.69-1.52-.69-2.4C14 3 16 1 18.5 1S23 3 23 5.5 21 10 18.5 10c-.88 0-1.7-.25-2.4-.69l-1.1 1.1v3.18l1.1 1.1c.7-.44 1.52-.69 2.4-.69 2.5 0 4.5 2 4.5 4.5S21 23 18.5 23 14 21 14 18.5c0-.88.25-1.7.69-2.4l-.1-.1H9.41l-.1.1c.44.7.69 1.52.69 2.4C10 21 8 23 5.5 23S1 21 1 18.5 3 14 5.5 14c.88 0 1.7.25 2.4.69l1.1-1.1v-3.18l-1.1-1.1c-.7.44-1.52.69-2.4.69C3 10 1 8 1 5.5S3 1 5.5 1m0 2A2.5 2.5 0 003 5.5 2.5 2.5 0 005.5 8 2.5 2.5 0 008 5.5 2.5 2.5 0 005.5 3m0 13A2.5 2.5 0 003 18.5 2.5 2.5 0 005.5 21 2.5 2.5 0 008 18.5 2.5 2.5 0 005.5 16m13-13A2.5 2.5 0 0016 5.5 2.5 2.5 0 0018.5 8 2.5 2.5 0 0021 5.5 2.5 2.5 0 0018.5 3m0 13a2.5 2.5 0 00-2.5 2.5 2.5 2.5 0 002.5 2.5 2.5 2.5 0 002.5-2.5 2.5 2.5 0 00-2.5-2.5M3.91 17.25l1.13.66c.13-.1.29-.16.46-.16a.75.75 0 01.75.75l-.01.1 1.13.65-.28.5-1.13-.66c-.13.1-.29.16-.46.16a.75.75 0 01-.75-.75l.01-.1-1.13-.65.28-.5m-.28-11l1.13-.65-.01-.1a.75.75 0 01.75-.75c.17 0 .33.06.46.16l1.13-.66.28.5-1.13.65.01.1a.75.75 0 01-.75.75c-.17 0-.33-.06-.46-.16l-1.13.66-.28-.5m13.28-2l1.13.66c.13-.1.29-.16.46-.16a.75.75 0 01.75.75l-.01.1 1.13.65-.28.5-1.13-.66c-.13.1-.29.16-.46.16a.75.75 0 01-.75-.75l.01-.1-1.13-.65.28-.5m-.28 15l1.12-.75a.75.75 0 01.75-.75c.17 0 .33.06.46.16l1.13-.66.28.5-1.12.75a.75.75 0 01-.75.75c-.17 0-.33-.06-.46-.16l-1.13.66-.28-.5z" />
        </svg>
    );
}

export default IconQuadcopter;
