import React, {useEffect, useState} from "react";
import Container from 'react-bootstrap/Container';
import '../../style.css'
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import VideoByImage from "../../components/videoByImage";
import DronesService from "../../api/dronesService";
import DroneMap from "./map";

export default function Drone() {
    const { droneId } = useParams()
    const [drone, setDrone] = useState()

    let navigate = useNavigate();

    useEffect(()=>{
        if (droneId) getDrone(droneId)
    },[droneId])

    const getDrone = async (number) =>{
        const res = await DronesService.getDroneByNumber(navigate, number)
        if (res.status == "OK"  &&  res.item) {
            setDrone(res.item)
        }
    }

    return <Container>
        {drone && drone.number && <>
            <VideoByImage number={drone.number} />
            <DroneMap drone_number={drone.number} />
        </>}
    </Container>
}

