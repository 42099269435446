import React, {useEffect} from "react";
import FormBuilder from '../components/form'
import AuthService from "../api/authService";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import {useDispatch} from "react-redux";
import {loginAction, logoutAction} from '../redux/authSlice'
import {showError} from "../redux/errorSlice";

const fields = [
    // {
    //     propertyName:'picture',
    //     name:'Picture',
    //     type:'image',
    //     value:''
    // },
    // {
    //     propertyName:'price',
    //     name:'Price',
    //     type:'textWithoutLang',
    //     value:''
    // },
    {
        propertyName:'name',
        name:'Логин',
        type:'text',
        value:''
    },
    {
        propertyName:'password',
        name:'Пароль',
        type:'password',
        value:''
    },
    // {
    //     propertyName:'description',
    //     name:'Description',
    //     type:'textarea',
    //     value:''
    // },
    // {
    //     propertyName:'veg',
    //     name:'Veg',
    //     type:'boolean',
    //     value:''
    // },
    // {
    //     propertyName:'visible',
    //     name:'Visible',
    //     type:'boolean',
    //     value:true
    // },

]
function Auth() {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    useEffect(()=>{
        dispatch(logoutAction())
    }, [])
    const submit = async (form)=>{
        const result = await AuthService.login( {name : form.name, password: form.password})
        if(result.status == 'OK'){
            dispatch(loginAction(result.userProfile))
            navigate('/drones')
        }
        else if(result.status == 'error') {
            if(result.errors){
                result.errors.forEach((error)=>dispatch(showError(error.msg)))
            }else
                dispatch(showError(result.message))
        }


    }
    return <div className="auth-container"><div style={{maxWidth:'350px', width: '100%'}}  className="d-grid gap-2">
        <FormBuilder fields={fields} buttonName={'Войти'}  submit={submit}/>
        <Button  variant="secondary" mode="contained" onClick={() => {navigate('/registration')}} style={{marginBottom:10,marginLeft:'20px', marginRight:'20px'}}>
            {'Регистрация'}
        </Button>
    </div></div>
}

export default Auth;
