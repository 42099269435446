import React, {useState, useEffect} from "react";

import './itemCard.css';
import config from "../config.json";


const DroneVideo = ({droneNumber}) => {
    const onImageClick = async (e)=>{
        console.log(e)
        console.log(e.target.alt)
        console.log(e.pageY - e.target.offsetTop,e.pageX -  e.target.offsetLeft)
        const realWidth = e.target.naturalWidth;
        const realHeight = e.target.naturalHeight;
        const resize = realWidth/e.target.width
        const coordX = parseInt(((e.pageX -  e.target.offsetLeft)*resize).toFixed(0))
        const coordY = parseInt(((e.pageY - e.target.offsetTop)*resize).toFixed(0))
        console.log(coordX,coordY)
        await sendClick({coordX, coordY, image:"none"})
    }
    const sendClick = async (values) =>{
        let data = {method: "post"}
        data['body'] = JSON.stringify({...values, dronNumber: droneNumber })
        data['headers'] = {
            'Content-Type': 'application/json'
        }
        let res = await fetch(config.apiServerPath + '/click/set/', data)

    }
    return <div>
            <img
                src={config.serverPath + ":8081/" + droneNumber}
                width="auto"
                height="auto"
                style={{maxWidth:'100%', marginBottom:20}}
                onClick={onImageClick}
            />
    </div>
}
export default DroneVideo
