import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';

import Table from "react-bootstrap/Table";
import '../style.css'
import {Button} from "react-bootstrap";
import IconMapLocationDot from "../icons/map";
import EmulatorsService from "../api/emulatorsService";
export default function Detectors() {
    const [emulators, setEmulators] = useState([])
    let navigate = useNavigate();
    useEffect(()=>{
        getEmulators()
    },[])

    const getEmulators = async () =>{
        const res = await EmulatorsService.get(navigate)
        if (res.status == "OK"  &&  res.items) {
            setEmulators(res.items)
        }
    }
    const Row = ({emulator, i})=>{
        return <tr>
            <td>{i+1}</td>
            <td>{emulator.name}</td>
            <td>{emulator.type}</td>
            <td>{emulator.detector_number}</td>
            <td>
                <IconMapLocationDot
                    onClick={() => goToEmulatorPage(emulator._id)}
                    style={{cursor:'pointer'}}
                    fill={'white'}
                    color={'white'}
                    width={'20px'}
                />

                {/*<MissionMapModalForm actions={props.mission.actions} />*/}
            </td>
        </tr>
    }
    const goToEmulatorPage = (emulatorId) => {
        navigate('/emulator/' + emulatorId)
    }
    return <Container>
        <h1 className="h">Эмуляторы</h1>
        <Button style={{marginBottom:20}} variant="secondary" onClick={() => goToEmulatorPage("")} >+ Добавить эмулятор</Button>
        <Table striped bordered hover>
            <thead>
            <tr>
                <th>#</th>
                <th>Название</th>
                <th>Тип</th>
                <th>Номер детектора</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {emulators.map((emulator,i)=><Row emulator={emulator} i={i} key={emulator._id}/>)}
            </tbody>
        </Table>

    </Container>
}

