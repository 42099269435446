// icon:map-marker-path | Material Design Icons https://materialdesignicons.com/ | Austin Andrews
import * as React from "react";

function IconMapMarkerPath(props) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="currentColor"
            height="1em"
            width="1em"
            {...props}
        >
            <path d="M18 15a3 3 0 013 3 3 3 0 01-3 3 2.99 2.99 0 01-2.83-2H14v-2h1.17c.41-1.17 1.52-2 2.83-2m0 2a1 1 0 00-1 1 1 1 0 001 1 1 1 0 001-1 1 1 0 00-1-1m0-9a1.43 1.43 0 001.43-1.43 1.43 1.43 0 10-2.86 0A1.43 1.43 0 0018 8m0-5.43a4 4 0 014 4C22 9.56 18 14 18 14s-4-4.44-4-7.43a4 4 0 014-4M8.83 17H10v2H8.83A2.99 2.99 0 016 21a3 3 0 01-3-3c0-1.31.83-2.42 2-2.83V14h2v1.17c.85.3 1.53.98 1.83 1.83M6 17a1 1 0 00-1 1 1 1 0 001 1 1 1 0 001-1 1 1 0 00-1-1M6 3a3 3 0 013 3c0 1.31-.83 2.42-2 2.83V10H5V8.83A2.99 2.99 0 013 6a3 3 0 013-3m0 2a1 1 0 00-1 1 1 1 0 001 1 1 1 0 001-1 1 1 0 00-1-1m5 14v-2h2v2h-2m-4-6H5v-2h2v2z" />
        </svg>
    );
}

export default IconMapMarkerPath;
