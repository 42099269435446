import {createSlice} from "@reduxjs/toolkit"


const cartSlice = createSlice({
    name:'cart',
    initialState:{
        cart:{},
        restaurant:''
    },
    reducers:{
        cartItemAdd(state, action){
            console.log('action.payload', action.payload, state.cart[action.payload.id])
            if(state.cart[action.payload.id] && state.cart[action.payload.id].count>0) state.cart[action.payload.id].count = state.cart[action.payload.id].count + 1
            else state.cart[action.payload.id] = {count:1, price:action.payload.price}
        },
        setCart(state, action){
            console.log('action.payload', action.payload)
            state.cart = action.payload.cart
            state.restaurant = action.payload.restaurant
        },
        cartRestaurantSet(state, action){
            console.log('action.payload', action.payload, state.cart[action.payload.id])
            state.restaurant = action.payload.id
        },
        cartItemRemove(state, action){
            if(state.cart[action.payload.id] && state.cart[action.payload.id].count > 1)
                state.cart[action.payload.id].count = state.cart[action.payload.id].count - 1
            else
                delete state.cart[action.payload.id]
            if(Object.keys(state.cart).length === 0){
                state.restaurant = ''
            }
            // const cartItemIndex = state.cart.findIndex((item)=>action.payload.id == item.id)
            // if(cartItemIndex != -1) state.cart = state.cart.splice(cartItemIndex,1)
        },
        cartClean(state){
            console.log('cartClean')
            state.cart = {}
            state.restaurant = ''
        }
    }
})

export default cartSlice.reducer
export const {cartItemAdd, cartItemRemove, cartRestaurantSet, cartClean, setCart} = cartSlice.actions