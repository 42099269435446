import Card from 'react-bootstrap/Card';
import config from "../config.json";
import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import FormBuilder from "./form";
const moment = require('moment');



const ItemFootPrintListCard = (props) => {
    const [show, setShow] = useState(false);

    return <Card   className="text-center" style={{ width: 200, marginRight:10 }} >
        <Card.Img onClick={()=>setShow(true)} variant="top" style={{cursor:'pointer', height: 150, width:'auto'}} src={config.filesPath + props.item.picture} />
        <Card.Body onClick={()=>setShow(true)} style={{cursor:'pointer'}}>
            <Card.Title>{props.item.result} kg</Card.Title>
            <Card.Text>{moment(props.item.date).format('DD.MM.YY HH:mm:ss')}</Card.Text>
        </Card.Body>
        <Modal show={show} onHide={()=>setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Carbon footprint {moment(props.item.date).format('DD.MM.YY HH:mm:ss')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!!props.item.metal && <div>Metal: {props.item.metal} kg</div>}
                {!!props.item.clothes && <div>Clothes: {props.item.clothes} kg</div>}
                {!!props.item.glass && <div>Glass: {props.item.glass} kg</div>}
                {!!props.item.plastic && <div>Plactic: {props.item.plastic} kg</div>}
                {!!props.item.paper && <div>Paper: {props.item.paper} kg</div>}
                {!!props.item.others && <div>Others: {props.item.others} kg</div>}
                <div style={{marginTop:20}}><b>Result</b> : {props.item.result} kg</div>
            </Modal.Body>

        </Modal>
    </Card>
}

export default ItemFootPrintListCard
