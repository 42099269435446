import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import DronesService from "../api/dronesService";
import Table from "react-bootstrap/Table";
import '../style.css'
import AccountEditModalForm from "../components/accountEditModalForm";
import DroneEditModalForm from "../components/droneEditModalForm";
import DroneDeleteModalForm from "../components/droneDeleteModalForm";
import DroneWatchStreamingModalForm from "../components/droneWatchStreamingModalForm";
import DroneWatchRecordModalForm from "../components/droneWatchRecordModalForm";
import DroneTargetModalForm from "../components/droneTargetModalForm";
import IconMapLocationDot from "../icons/map";
export default function Drones() {
    const [drones, setDrones] = useState([])
    let navigate = useNavigate();
    const goToDronePage = (droneId) => {
        navigate('/drone/' + droneId)
    }
    useEffect(()=>{
        getDrones()
    },[])
    useEffect(()=>{
        console.log(drones)
    },[drones])
    const getDrones = async () =>{
        const res = await DronesService.get(navigate)
        if (res.status == "OK"  &&  res.items) {
            setDrones(res.items)
        }
    }

    const Row = (props)=>{
        return <tr>
            <td>{props.i+1}</td>
            <td>{props.drone.name}</td>
            <td>{props.drone.number}</td>
            <td>{props.drone.vpnAddress}</td>
            <td><div style={{display:'flex'}}>
                <DroneEditModalForm values={props.drone} refresh={getDrones} />
                <div style={{marginLeft:10}} />
                <DroneDeleteModalForm values={props.drone} refresh={getDrones} />
              </div></td>
            <td><div style={{display:'flex'}}>
                <DroneWatchRecordModalForm number={props.drone.number}/>
                <div style={{marginLeft:10}} />
                <IconMapLocationDot onClick={() => goToDronePage(props.drone.number)} style={{cursor:'pointer'}} fill={'white'} color={'white'} width={'20px'} />
            </div></td>
        </tr>
    }

    return <Container>
        <h1 className="h">Устройства</h1>
        <DroneEditModalForm refresh={getDrones} />
        <Table responsive striped bordered hover>
            <thead>
            <tr>
                <th>#</th>
                <th>Имя</th>
                <th>Номер</th>
                <th>VPN ip</th>
                <th>Действия</th>
            </tr>
            </thead>
            <tbody>
            {drones.map((drone,i)=><Row drone={drone} i={i} key={drone._id} />)}
            </tbody>
        </Table>

    </Container>
}

