import React, {useState, useEffect} from "react";

import Modal from 'react-bootstrap/Modal';
import FormBuilder from "../form";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

const fields = [
    {
        propertyName:'isTarget',
        name:'Цель',
        type:'boolean',
    },
    {
        propertyName:'lat',
        name:'Долгота',
        type:'text',
        value:''
    },
    {
        propertyName:'lon',
        name:'Широта',
        type:'text',
        value:''
    },
    {
        propertyName:'alt',
        name:'Высота',
        type:'text',
        value:''
    },
    {
        propertyName:'wait',
        name:'Задержка',
        type:'text',
        value:''
    },
]
const PointEditModalForm = ({ point, onSubmit, onClose, show, setShow, editMode }) => {

    return <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton>
            <Modal.Title>{ editMode ? 'Изменить данные точки' : "Просмотр данных точки" }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <FormBuilder fields={fields} values={point} buttonName={ editMode ? 'Сохранить' : "Закрыть" }  submit={onSubmit}/>
        </Modal.Body>
    </Modal>
}
export default PointEditModalForm
