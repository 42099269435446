import React, {useEffect, useState} from "react";


function Test({onSubmit}) {
    const [user, setUser] = useState(() => ({firstName: "", lastName: ""}));

    const handleFirstNameChange = (e)=>{
        setUser({...user, firstName: e.target.value})
    }
    const handleLastNameChange = (e)=>{
        setUser({...user, lastName: e.target.value})
    }
    const handleSubmit = (e)=>{
        e.preventDefault()
        onSubmit(user)
    }

    return <form onSubmit={handleSubmit}>
        <input
            type={"text"}
            value={user.firstName}
            onChange={handleFirstNameChange}
        />
        <input
            type={"text"}
            value={user.lastName}
            onChange={handleLastNameChange}
        />
        <input type={"submit"} value={"submit"} />
    </form>
}

export default Test;
