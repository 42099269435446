import fetchData from "./fetchData";
import api from "./api";

export default class EmulatorsService {
  static async get(navigation) {
    const res = await fetchData(api.getEmulators, navigation, 'get')
    return res
  }
  static async getEmulator(navigation, id) {
    const res = await fetchData(api.getEmulator, navigation, 'get', id)
    return res
  }
  static async getTargets(navigation, id) {
    const res = await fetchData(api.getTargets, navigation, 'get', id)
    return res
  }
  static async sendTarget(navigation, body) {
    const res = await fetchData(`${api.sendTarget}/${body.detectorNumber}`, navigation, 'post', {...body})
    return res
  }
  static async merge(navigation, body) {
    const res = await fetchData(api.mergeEmulator, navigation, 'post', {...body})
    return res
  }
  static async delete(navigation, id) {
    const res = await fetchData(api.deleteDetector, navigation, 'get',id)
    return res
  }

}
