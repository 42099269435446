import {useNavigate} from "react-router-dom";
import AuthService from "../api/authService";
import IconLogout from "../icons/logout";

import React, {useEffect, useState} from "react";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {useDispatch, useSelector} from "react-redux";
import {loginAction, logoutAction} from "../redux/authSlice";
import IconLoginBoxLine from "../icons/login";
import Cookies from "js-cookie";
import Image from "react-bootstrap/Image";
import config from "../config.json";
import IconMapMarkerPath from "../icons/path";
import IconQuadcopter from "../icons/drone";
import IconEye from "../icons/eye";
import IconRadar from "../icons/radar";
const iconsSize = 35

export default function Navigator () {
    const auth = useSelector(state => state.auth)
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const expand = false;
    const [show, setShow] = useState(false)
    const goToLogin =  ()=>{
        navigate('/login')
    }
    const goToAccount =  ()=>{
        navigate('/account')
    }

    const logout = async ()=>{
        const result = await AuthService.logout(navigate)
        if(result){
            dispatch(logoutAction())
            //dispatch(dropShippingAddress())
        }
    }
    return (
        <Navbar key={expand} expand={expand} className="justify-content-between">
                <Container fluid >
                    <Navbar.Text>
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={()=>setShow(!show)}/>
                        <Navbar.Brand href="#" style={{marginLeft:20}}>Центр управления БВС</Navbar.Brand>
                    </Navbar.Text>
                    {auth.userProfile && <Navbar.Text>
                        { auth.userProfile.photo && <Image onClick={goToAccount} style={{marginRight:10, cursor:'pointer'}} width={45} height={45} src={config.filesPath + auth.userProfile.photo} roundedCircle />}

                        <Button variant="link" style={{color:'white'}} onClick={goToAccount}>{auth.userProfile.name}</Button>
                        <IconLogout  style={{ marginLeft:'20px'}} onClick={logout} height={'25px'} color={'white'} stroke={'white'}/>
                    </Navbar.Text>}
                    {!auth.userProfile && <Navbar.Text>
                        <IconLoginBoxLine  style={{ marginLeft:'20px'}} onClick={goToLogin} height={'30px'} color={'white'} stroke={'white'}/>
                    </Navbar.Text>}
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-${expand}`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                        placement="start"
                        show={show}
                        onHide={()=>setShow(!show)}
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                Меню
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="justify-content-end flex-grow-1 pe-3">
                                <Nav.Link onClick={()=>{navigate('/drones');setShow(!show)}}>
                                    <IconQuadcopter width={iconsSize} height={iconsSize} style={{marginRight:10}} />
                                    Устройства
                                </Nav.Link>
                                <Nav.Link onClick={()=>{navigate('/missions');setShow(!show)}}>
                                    <IconMapMarkerPath width={iconsSize} height={iconsSize} style={{marginRight:10}} />
                                    Полетные задания
                                </Nav.Link>
                                <Nav.Link onClick={()=>{navigate('/detectors');setShow(!show)}}>
                                    <IconRadar width={iconsSize} height={iconsSize} style={{marginRight:10}} />
                                    НСД "НЕБО"
                                </Nav.Link>
                                <Nav.Link onClick={()=>{navigate('/emulators');setShow(!show)}}>
                                    <IconRadar width={iconsSize} height={iconsSize} style={{marginRight:10}} />
                                    Эмуляторы
                                </Nav.Link>
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
    );
}
