import React, {useState, useEffect} from "react";

import Modal from 'react-bootstrap/Modal';
import FormBuilder from "../../../components/form";
import {showError} from "../../../redux/errorSlice";
import DetectorsService from "../../../api/detectorsService";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import EmulatorsService from "../../../api/emulatorsService";

const fields = [
    {
        propertyName:'name',
        name:'Название',
        type:'text',
        value:''
    },
    {
        propertyName:'type',
        name:'Тип',
        buttonText: 'Тип',
        type:'select',
        items: [
            {name:"Шторм", value: "storm"},
            {name:"Прочие", value: "etc"},
        ],
        value:''
    },
    {
        propertyName:'detector_number',
        name:'Номер детектора',
        type:'text',
        value:''
    },

]
const EmulatorEditModalForm = ({ point, onSubmit, onClose, show, setShow, oldValues, editMode, setPosition }) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const save = async (values)=>{
            const { current, ...restValues} = values;

            let body = {
                ...oldValues,
                ...restValues,
            }
        const result = await EmulatorsService.merge( navigate, body);

        if(result.status == 'error') {
            if(result.errors){
                result.errors.forEach((error)=>dispatch(showError(error.msg)))
            }else
                dispatch(showError(result.message))
        }
        // }
        onClose()
    }
    return <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton>
            <Modal.Title>{ editMode ? 'Изменить данные точки' : "Просмотр данных точки" }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <FormBuilder fields={fields} values={{ ...oldValues, ...point}} buttonName={ 'Сохранить'}  submit={save}/>
        </Modal.Body>
    </Modal>
}
export default EmulatorEditModalForm
